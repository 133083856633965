import { useContext, useEffect, useRef, useState } from "react";
import Navbar from "../../componentes/Navbar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { withRouter } from "react-router-dom";
import { Loading } from "../../componentes/Loading";
import AgenteService from "../../app/service/AgenteService";
import ProcessoAutomaticoService from "../../app/service/ProcessoAutomaticoService";
import { formatDateAgent } from "../../utils/DateFormat";
import { formatarNumeroParaReais } from "../../utils/NumberFormat";

function PainelAgente(props) {
  const [agentes, setAgentes] = useState([]);
  const [consultaNome, setConsultaNome] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [tribunaisList, setTribunaisList] = useState([]);
  const agenteService = new AgenteService();
  const service = new ProcessoAutomaticoService();
  
  const toast = useRef(null);
  const empresaId = 1;

  // Função para buscar os agentes
  const buscarAgentes = async () => {
    setLoading(true);
    try {
      const res = await agenteService.getAllAgentes(empresaId);
      setAgentes(res.data);
    } catch (error) {
      console.error("Erro ao buscar agentes:", error);
    }
    setLoading(false);
  };

  // Busca os tribunais para fazer a conversão do id para nome
  const getTribunais = () => {
    service
      .getSistemasWithCertified()
      .then((res) => {
        const data = res.data;
        setTribunaisList(data);
      })
      .catch((error) => {
        const detail = error.response.data.detail || "Erro ao buscar tribunais";
        toast.current.show({
          severity: "error",
          summary: "Agente",
          detail: `${detail}`,
          life: 4000,
        });
      });
  };

  useEffect(() => {
    buscarAgentes();
    getTribunais();
  }, []);

  // Filtra os agentes pelo nome (campo "nome" do agente)
  const agentesFiltrados = agentes.filter((agente) =>
    agente.nome.toLowerCase().includes(consultaNome.toLowerCase())
  );

  const onRowClick = (rowData) => {
    // Exemplo: redireciona para a página de detalhes do agente
    props.history.push(`/empresa/agentes/detalhes/${rowData.codigo}`);
  };

  // Recupera o nome do tribunal a partir do id
  function getTribunalNameById(id) {
    const tribunal = tribunaisList.find((tribunal) => tribunal.codigo === id);
    return tribunal ? tribunal.systemName : "Tribunal não encontrado";
  }

  // Renderiza a tabela de agentes
  const renderTabelaAgentes = () => {
    return (
      <div className="pagination-container">
        <DataTable
          value={agentesFiltrados}
          paginator
          rows={itemsPerPage}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
          emptyMessage="Nenhum agente encontrado"
          selectionMode="single"
          onRowClick={(e) => onRowClick(e.data)}
        >
          <Column field="codigo" header="Código" style={{ width: "100px" }} />
          <Column field="nome" header="Nome" />
          <Column
            field="tribunal"
            header="Tribunal"
            body={(rowData) => getTribunalNameById(rowData.tribunal)}
          />
          <Column
            field="valorCausa"
            header="Valor Causa"
            body={(rowData) => formatarNumeroParaReais(rowData.valorCausa)}
          />
          <Column
            field="dataDistribuicaoInicio"
            header="Data Início"
            body={(rowData) => formatDateAgent(rowData.dataDistribuicaoInicio)}
          />
          <Column
            field="dataDistribuicaoFim"
            header="Data Fim"
            body={(rowData) => formatDateAgent(rowData.dataDistribuicaoFim)}
          />
          <Column field="status" header="Status" />
          <Column 
            field="horaExecucao" 
            header="Hora Execução" 
            body={(rowData) => rowData.horaExecucao.slice(0, 5)} 
          />
          <Column
            field="findPrecatorios"
            header="Busca Precatórios"
            body={(rowData) => (rowData.findPrecatorios ? "Sim" : "Não")}
          />
        </DataTable>
      </div>
    );
  };

  return (
    <>
      <Navbar />
      <Loading open={loading} />
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary card-outline">
                  <div className="card-header">
                    <h3 className="card-title">
                      <i className="fas fa-robot nav-icon"></i>
                      <span> Painel de Agentes</span>
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row mb-3">
                      <div className="col-sm-6 d-flex justify-content-start">
                        <div className="form-inline">
                          <label className="mr-2">Itens por página:</label>
                          <select
                            className="form-control"
                            value={itemsPerPage}
                            onChange={(e) =>
                              setItemsPerPage(Number(e.target.value))
                            }
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 d-flex justify-content-end">
                        <div
                          className="input-group"
                          style={{ width: "300px", height: "35px" }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Pesquisar agente"
                            value={consultaNome}
                            onChange={(e) => setConsultaNome(e.target.value)}
                          />
                          <span className="input-group-prepend pointer">
                            <button type="submit" className="input-group-text">
                              <i className="fas fa-search"></i>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                    {renderTabelaAgentes()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default withRouter(PainelAgente);
