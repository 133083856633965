import {useContext, useEffect, useRef, useState} from "react";
import {ButtonEtrium} from "../../componentes/ButtonEtrium";
import Navbar from "../../componentes/Navbar";
import {Button} from "primereact/button";
import {AgentCreateDialog} from "../../componentes/Processo/Agentes/AgentCreateDialog";
import {Toast} from "primereact/toast";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import AgenteService from "../../app/service/AgenteService";
import ProcessoAutomaticoService from "../../app/service/ProcessoAutomaticoService";
import {formatDateAgent} from "../../utils/DateFormat";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {AgentUpdateDialog} from "../../componentes/Processo/Agentes/AgentUpdateDialog";
import {confirmDialog} from "primereact/confirmdialog";


export const Agentes = () => {

    const {usuarioAutenticado} = useContext(AuthContext);

    const agenteService = new AgenteService()
    const service = new ProcessoAutomaticoService()

    const [agents, setAgents] = useState([])
    const [agentId, setAgentId] = useState(null)
    const [tribunaisList, setTibunaisList] = useState([]);

    const [openDialogCreateAgente, setDialogCreateAgente] = useState(false)
    const [openDialogUpdateAgente, setDialogUpdateAgente] = useState(false)

    const toast = useRef(null)

    const addNewAgent = () => {
        setDialogCreateAgente(true)
    };

    const updateAgent = (agentId) => {
        setAgentId(agentId)
        setDialogUpdateAgente(true)
    }

    const removeAgent = (agentId) => {
        confirmDialog({
            message: 'Deseja realmente excluir este agente?',
            header: 'Exclusão de Agente',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: () => handleConfirm(agentId)
        });
    };

    const loadAgents = () => {
        agenteService.getAgentesByEmpresa(usuarioAutenticado.empresa.codigo).then((res) => {
            setAgents(res.data)
        }).catch((error) => {
            let detail = error.response.data.detail || "Erro ao buscar agentes"
            toast.current.show({severity: 'error', summary: "Agente", detail: detail, life: 4000})
        })
    }
    const getTribunais = () => {
        service.getSistemasWithCertified().then((res) => {
            const data = res.data
            setTibunaisList(data)
        })
            .catch(
                (error) => {
                    const detail = error.response.data.detail || "Erro ao buscar tribunais"
                    toast.current.show({
                        severity: 'error',
                        summary: 'Agente',
                        detail: `${detail}`,
                        life: 4000
                    })
                }
            )
    }

    function getTribunalNameById(id) {
        const tribunal = tribunaisList.find(tribunal => tribunal.codigo === id)
        return tribunal ? tribunal.systemName : "Tribunal não encontrado"
    }

    function formatNumber(num) {
        if (IsNullOrEmpty(num)) {
            return "R$ 0";
        }
        return "R$ " + Number(num).toLocaleString('pt-BR', { maximumFractionDigits: 0 });
    }

    useEffect(() => {
        loadAgents()
        getTribunais()
    }, []);


    const handleConfirm = (id) => {
        agenteService.deleteAgente(id).then(() => {
            loadAgents()
        }).catch((error) => {
            let detail = error.response.data.detail || "Erro ao excluir agente"
            toast.current.show({severity: 'error', summary: "Agente", detail: detail, life: 4000})
        })
    }

    const formatAssuntosDisplay = (assuntos, maxLength = 50) => {
        if (!assuntos) return "";
        const assuntosStr = Array.isArray(assuntos) ? assuntos.join(", ") : assuntos;
        if (assuntosStr.length > maxLength) {
            return assuntosStr.substring(0, maxLength) + "...";
        }
        return assuntosStr;
    };

    function formatHora(hora) {
        if (!hora) return "00:00";
        return hora.slice(0,5);
      }


    return (
        <>
            <Navbar/>
            <Toast ref={toast}/>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <section className="content-header">
                        <div className="card card-primary card-outline">
                            <div className="card-header row align-items-center gap-0">
                                <h3 className="card-title">
                                    {/*TODO: ajustar Icone*/}
                                    <i className="nav-icon fas fa-robot nav-icon"></i>
                                    <span className="ml-1">Agentes</span>
                                </h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="mb-4">
                                            <ButtonEtrium
                                                style={{height: '37px'}}
                                                buttonType={'primary'} children={"Adicionar agente"}
                                                onClick={addNewAgent}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    {agents && agents.map(agent => (
                                        <div className="col-sm-4" key={agent.codigo}>
                                            <div className="card card-primary card-outline "
                                                >
                                                <div
                                                    className={'card-header d-flex flex-1 justify-content-between align-items-center'}>
                                                    <h3 className="card-title d-flex flex-1 align-items-center">
                                                        <i className="pi pi-user"></i>
                                                        <span className={"ml-1"}>{agent.nome}</span>
                                                    </h3>
                                                    <Button
                                                        icon={"pi pi-trash"}
                                                        className={'p-button-text p-button-danger p-button-sm'}
                                                        children={""}
                                                        onClick={() => removeAgent(agent.codigo)}
                                                    />
                                                </div>
                                                <div className="card-body cursor"  onClick={() => updateAgent(agent.codigo)}>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="row">
                                                                <div className="col-sm-12 mb-2">
                                                                    <span className={"flex-1 font-bold"}>Status</span>
                                                                    <span className={`text-white px-1 ${
                                                                        agent.status === "Operante" ? "bg-success" :
                                                                        agent.status === "Em execução" ? "bg-primary" :
                                                                        "bg-danger"
                                                                    }`}>
                                                                        {agent.status}
                                                                    </span>
                                                                </div>
                                                                <div className="col-sm-12 mb-2">
                                                                    <span className={"flex-1 font-bold"}>Tribunal</span>
                                                                    <span>{getTribunalNameById(agent.tribunal)}</span>
                                                                </div>
                                                                <div className="col-sm-12 mb-2">
                                                                <span
                                                                    className={"flex-1 font-bold"}>Intervalo de buscas</span>
                                                                    <span>{agent.intervaloBusca + " dia(s)"} </span>
                                                                </div>
                                                                <div className="col-sm-12 mb-2">
                                                                <span
                                                                    className={"flex-1 font-bold"}>Hora da Execução</span>
                                                                    <span>{formatHora(agent.horaExecucao)}</span>
                                                                </div>
                                                                <div className="col-sm-12 mb-2">
                                                                    <span className={"flex-1 font-bold"}>Data de distribuição inicial</span>
                                                                    <span>{formatDateAgent(agent.dataDistribuicaoInicio)}</span>
                                                                </div>
                                                                <div className="col-sm-12 mb-2">
                                                                    <span className={"flex-1 font-bold"}>Data de distribuição final</span>
                                                                    <span>{formatDateAgent(agent.dataDistribuicaoFim)}</span>
                                                                </div>

                                                                <div className="col-sm-12 mb-2">
                                                                    <span
                                                                        className={"flex-1 font-bold"}>Polo ativo</span>
                                                                    <span>{agent.poloAtivo}</span>
                                                                </div>
                                                                <div className="col-sm-12 mb-2">
                                                                    <span
                                                                        className={"flex-1 font-bold"}>Polo passivo</span>
                                                                    <span>{agent.poloPassivo}</span>
                                                                </div>
                                                                <div className="col-sm-12 mb-2">
                                                                    <span
                                                                        className={"flex-1 font-bold"}>Valor da causa</span>
                                                                    <span>{formatNumber(agent.valorCausa)}</span>
                                                                </div>
                                                                <div className="col-sm-12 mb-2">
                                                                    <span className="flex-1 font-bold">Assuntos</span>
                                                                    <span
                                                                        title={
                                                                            Array.isArray(agent.assuntos)
                                                                                ? agent.assuntos.join(", ")
                                                                                : agent.assuntos
                                                                        }
                                                                    >
                                                                        {formatAssuntosDisplay(agent.assuntos)}
                                                                    </span>
                                                                </div>

                                                                <div className="col-sm-12 mb-2">
                                                                    <span className={"flex-1 font-bold"}>Buscar Precatório</span>
                                                                    <span>{agent.findPrecatorios === true ? "Sim" : "Não"}</span>
                                                                </div>



                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
            {openDialogCreateAgente && <AgentCreateDialog open={openDialogCreateAgente} onClose={() => {
                setDialogCreateAgente(false)
            }} onConfirm={() => {
                loadAgents()
            }} toast={toast.current}/>}

            {openDialogUpdateAgente && <AgentUpdateDialog
                open={openDialogUpdateAgente}
                onClose={() => {
                    setDialogUpdateAgente(false)
                }}
                onConfirm={() => {
                    loadAgents()
                }}
                toast={toast.current}
                agenteId={agentId}
            />}
        </>
    );
}