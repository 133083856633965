import React, { useEffect, useState, useRef } from 'react';
import PaymentPlanosService from '../../app/service/PaymentPlanosService';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { strings } from '../../utils/strings';
import { differenceInDays } from 'date-fns';
import { formatDateHoraTela } from '../../utils/DateFormat';
import { formatarNumeroParaReais } from '../../utils/NumberFormat';

const paymentMethodTypes = {
  1: 'Cartão de Crédito',
  2: 'Boleto Bancário',
  4: 'Cartão de Débito',
  5: 'QR Code Crédito',
  6: 'Pix',
  7: 'QRCode Débito'
};

const paymentMethodBrands = {
  1: 'Visa',
  2: 'Master',
  3: 'AmericanExpress',
  4: 'Diners',
  5: 'Elo',
  6: 'Aura',
  7: 'JCB',
  8: 'Discover',
  9: 'HiperCard'
};

const paymentStatusColors = {
  'Pendente': 'orange',
  'Negada': 'red',
  'Pago': 'green',
  'Ativa': 'green',
  'Autorizado': 'blue',
  'Desativado pelo usuário': 'gray',
  'Desativado por cartão de crédito expirado': 'red',
  'Desativado por número máximo de tentativas': 'red',
  'Aguardando conciliação': 'orange',
  'Finalizada': 'gray',
  1: 'orange', // Pendente
  2: 'green', // Pago
  3: 'red', // Negada
  4: 'gray', // Expirado
  5: 'purple', // Cancelado
  6: 'blue', // Não Finalizado
  7: 'teal', // Autorizado
  8: 'black' // Chargeback
};


const TransacoesEmpresa = ({ empresaCodigo }) => {
  const [transacoes, setTransacoes] = useState([]);
  const [expandedTransactions, setExpandedTransactions] = useState({});
  const paymentPlanosService = new PaymentPlanosService();
  const toast = useRef(null);

  useEffect(() => {
    const fetchTransacoes = async () => {
      try {
        const response = await paymentPlanosService.buscarTransacoesRecentesPorCdEmpresa(empresaCodigo);
        setTransacoes(response.data);
      } catch (error) {
        console.error('Erro ao buscar transações:', error);
      }
    };

    fetchTransacoes();
  }, [empresaCodigo]);

  const toggleExpand = (orderId) => {
    setExpandedTransactions(prevState => ({
      ...prevState,
      [orderId]: !prevState[orderId]
    }));
  };

  const handleButtonClick = async (checkoutCieloOrderNumber, transacaoDate) => {
    const currentDate = new Date();
    const transactionDate = new Date(transacaoDate);
    const diffDays = differenceInDays(currentDate, transactionDate);

    if (diffDays > 7) {
      toast.current.show({
        severity: 'error',
        summary: 'Erro',
        detail: 'Ação não permitida. A transação foi realizada há mais de 7 dias.',
        life: 4000
      });
    } else {
      try {
        await paymentPlanosService.desativarRecurrence(checkoutCieloOrderNumber);
        toast.current.show({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Recorrência desativada com sucesso.',
          life: 4000
        });
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Erro',
          detail: 'Erro ao desativar a recorrência.',
          life: 4000
        });
        console.log(error);
      }
    }
  };

  const getStatusStyle = (status) => {
    return {
      backgroundColor: paymentStatusColors[status] || 'white',
      color: 'white',
      padding: '2px 5px',
      borderRadius: '3px'
    };
  };


  const getStatusText = (status) => {
    switch (status) {
      case 1: return 'Pendente';
      case 2: return 'Pago';
      case 3: return 'Negado';
      case 4: return 'Expirado';
      case 5: return 'Cancelado';
      case 6: return 'Não Finalizado';
      case 7: return 'Autorizado';
      case 8: return 'Chargeback';
      default: return 'Desconhecido';
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className='card-header'>
        <h3 className="card-title row-etrium gap-1 align-center">
          <i className="fa fa-shopping-cart"></i>
          <span>Transações da empresa</span>
        </h3>
      </div>
      <div style={{ padding: '20px', borderRadius: '5px' }}>
        <ul style={{ listStyleType: 'none', padding: 0, color: 'black', fontWeight: '450' }}>
          {transacoes.map((transacao, index) => (
            transacao.transacao && (
              <li
                key={transacao.cdempresa_order}
                style={{
                  marginBottom: '10px',
                  padding: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  color: 'black',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column'
                }}
                onClick={() => toggleExpand(transacao.cdempresa_order)}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ flex: 1 }}>
                    <p style={{ margin: 0, color: 'black' }}>Nome: {transacao.transacao.customer_name}</p>
                    <p style={{ margin: 0, color: 'black' }}>Telefone: {transacao.transacao.customer_phone}</p>
                    <p style={{ margin: 0, color: 'black' }}>Documento: {transacao.transacao.customer_identity}</p>
                    <p style={{ margin: 0, color: 'black' }}>Email: {transacao.transacao.customer_email}</p>
                    <p style={{ margin: 0, color: 'black' }}>
                      <br />
                    </p>
                    <p style={{ margin: 0, color: 'black' }}>Data da Transação: {formatDateHoraTela(transacao.transacao.created_date)}</p>

                  </div>
                  <div
                    style={{
                      borderLeft: '2px solid #bdbdbd86',
                      height: '101px',
                      margin: '0 100px',
                      transition: 'height 0.3s ease'
                    }}
                  ></div>
                  <div style={{ flex: 1 }}>
                    <p style={{ margin: 0, color: 'black', fontWeight: '600' }}>Pedido: {transacao.order_number}</p>
                    <p style={{ margin: 0, color: 'black', fontWeight: '600' }}>Valor: {formatarNumeroParaReais(transacao.transacao.amount)}</p>
                    <p style={{ margin: 0, color: 'black' }}>
                      Status do Pagamento: <span style={getStatusStyle(transacao.transacao.payment_status)}>{getStatusText(transacao.transacao.payment_status)}</span>
                    </p>
                    <p style={{ margin: 0, color: 'black' }}>Cartão: {transacao.transacao.payment_maskedcreditcard}</p>
                    <p style={{ margin: 0, color: 'black' }}>Método de Pagamento: {paymentMethodTypes[transacao.transacao.payment_method_type]}</p>
                    <p style={{ margin: 0, color: 'black' }}>Bandeira do Cartão: {paymentMethodBrands[transacao.transacao.payment_method_brand]}</p>
                  </div>
                </div>
              </li>
            )
          ))}
        </ul>
      </div>
    </>
  );
};

export default TransacoesEmpresa;
