import ApiService from '../apiservice'

class ResultProcessosBuscadosService extends ApiService {

    constructor() {
        super('/result-processos')
    }


    getEndpoint(codigo) {
        return this.get(`/user/${codigo}`)
    }

    getProcessoEmpresa(codigo) {
        return this.get(`/empresa/${codigo}`)
    }
}

export default ResultProcessosBuscadosService