import { IsNullOrEmpty } from "./IsNullOrEmpy";

// Formata o número para reais
export function formatarNumeroParaReais(num) {
  if (IsNullOrEmpty(num)) {
    return "R$ 0,00";
  }

  return (
    "R$ " +
    Number(num).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );
}
