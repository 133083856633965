import {useContext, useEffect, useState} from "react";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";
import {ButtonEtrium} from "../../ButtonEtrium";
import {InputNumber} from "primereact/inputnumber";
import {Dropdown} from "primereact/dropdown";
import {IsNullOrEmpty} from "../../../utils/IsNullOrEmpy";
import ProcessoAutomaticoService from "../../../app/service/ProcessoAutomaticoService";
import {AuthContext} from "../../../main/ProvedorAutenticacao";
import {DateFormat, getCurrentHour} from "../../../utils/DateFormat";
import AgenteService from "../../../app/service/AgenteService";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { DialogAssuntoAgente } from "../../DialogAssuntoAgente";
import AgenteAssuntoService from "../../../app/service/AgenteAssuntoService";
import { InputSwitch } from "primereact/inputswitch";

export const AgentCreateDialog = ({open, onClose, onConfirm, toast}) => {

    const [agentState, setAgentState] = useState({
        name: "",
        tribunal: null,
        credential: null,
        dataDistribuicaoInicio: "",
        dataDistribuicaoFim: "",
        valorCausa: 0,
        intervaloBusca: 30,
        poloAtivo: "",
        poloPassivo: "",
        findPrecatorios: 0,
        horaExecucao: "00:00",
    })
    const [credenciaisList, setCredenciaisList] = useState([]);
    const [tribunaisList, setTibunaisList] = useState([]);

    const {usuarioAutenticado} = useContext(AuthContext);
    const [assuntosOptions, setAssuntosOptions] = useState([]);
    const [novoAssunto, setNovoAssunto] = useState(false)
    const [isHoraExecucaoDisabled, setIsHoraExecucaoDisabled] = useState(false);
    const service = new ProcessoAutomaticoService()
    const agenteService = new AgenteService()
    const agenteAssuntoService = new AgenteAssuntoService()


    const onAgentStateChange = (e) => {
        const {name, value} = e.target;
        setAgentState({
            ...agentState,
            [name]: value
        });
    }

    const onAssuntoChange = (e) => {
        setAgentState({
          ...agentState,
          assuntos: e.value
        });
    };

    const handleIntervaloBuscaChange = (e) => {
        const selectedValue = e.value;
        let horaExecucaoAtualizada = agentState.horaExecucao;
        let disableHoraExecucao = false;
    
        if (selectedValue === 0) {
          horaExecucaoAtualizada = getCurrentHour();
          disableHoraExecucao = true;
        } else {
          disableHoraExecucao = false;
        }
    
        setAgentState({
            ...agentState,
            intervaloBusca: selectedValue,
            horaExecucao: horaExecucaoAtualizada,
          });
    };

    const consultarAssuntos = async () => {
        try {
          const response = await agenteAssuntoService.getAssuntosByEmpresa(usuarioAutenticado.empresa.codigo);
          const assuntosArray = response.data.map(item => item.assunto);
          const uniqueAssuntos = [...new Set(assuntosArray)];
          const options = uniqueAssuntos.map(item => ({
            label: item,
            value: item
          }));
          setAssuntosOptions(options);
        } catch (error) {
          toast.show({
            severity: "error",
            summary: "Atividades",
            detail: "Erro ao carregar assuntos",
            life: 4000
          });
        }
      };


    const handleCreateAgent = () => {
        if (IsNullOrEmpty(agentState.name)) {
            return toast.show({
                severity: 'error',
                summary: 'Criar Agente',
                detail: 'Nome do agente obrigatório',
                life: 4000
            });
        }
        if (IsNullOrEmpty(agentState.tribunal)) {
            return toast.show({
                severity: 'error',
                summary: 'Criar Agente',
                detail: 'Local de busca é obrigatório',
                life: 4000
            });
        }
        if (IsNullOrEmpty(agentState.credential)) {
            return toast.show({
                severity: 'error',
                summary: 'Criar Agente',
                detail: 'Credencial de acesso é obrigatória',
                life: 4000
            });
        }

        let dataInicio = null
        let dataFim = null
        if (!IsNullOrEmpty(agentState.dataDistribuicaoInicio)) {
            dataInicio = DateFormat(agentState.dataDistribuicaoInicio)
        }
        if (!IsNullOrEmpty(agentState.dataDistribuicaoFim)) {
            dataFim = DateFormat(agentState.dataDistribuicaoFim)
        }
        let newAgente = {
            nome: agentState.name,
            tribunal: agentState.tribunal.codigo,
            credencial: agentState.credential.codigo,
            dataDistribuicaoInicio: dataInicio,
            dataDistribuicaoFim: dataFim,
            valorCausa: agentState.valorCausa,
            intervaloBusca: agentState.intervaloBusca,
            poloAtivo: agentState.poloAtivo,
            poloPassivo: agentState.poloPassivo,
            empresa: usuarioAutenticado.empresa.codigo,
            assuntos: agentState.assuntos,
            findPrecatorios: agentState.findPrecatorios,
            horaExecucao: agentState.horaExecucao
        }
        
        agenteService.createAgente(newAgente).then(() => {
            toast.show({severity: 'success', summary: 'Criar Agente', detail: 'Agente criado com sucesso', life: 4000});
            onConfirm()
            onClose(false)
        })
            .catch((error) => {
                let detail = error.response.data.detail || "Erro ao criar agente"
                toast.show({severity: 'error', summary: 'Criar Agente', detail: detail, life: 4000});
            })
    };

    const renderFooterCreate = () => {
        return (
            <div className={"row-etrium"}>
                <ButtonEtrium label="Confirmar" onClick={handleCreateAgent}/>
            </div>
        );
    };

    const getUsuarios = () => {
        service.getUsuariosComCertificadoPorEmpresa(usuarioAutenticado.empresa.codigo).then((res) => {
            const data = res.data
            setCredenciaisList(data)
            if (data.length > 0) {
                setAgentState({
                    ...agentState,
                    credential: data[0]
                });
            }
        })
            .catch(
                (error) => {
                    const detail = error.response.data.detail || "Erro ao buscar credenciais"
                    toast.show({
                        severity: 'error',
                        summary: 'Erro',
                        detail: `${detail}`,
                        life: 4000
                    })
                }
            )
    }

    const getTribunais = () => {
        service.getSistemasWithCertified().then((res) => {
            const data = res.data
            if (data && data.length > 0) {
                setTibunaisList([data[0]]);
              } else {
                setTibunaisList([]);
              }
        })
            .catch(
                (error) => {
                    const detail = error.response.data.detail || "Erro ao buscar tribunais"
                    toast.show({
                        severity: 'error',
                        summary: 'Erro',
                        detail: `${detail}`,
                        life: 4000
                    })
                }
            )
    }

    useEffect(() => {
        getUsuarios()
        getTribunais()
        consultarAssuntos();
    }, []);
    return (
        <Dialog
            header={"Criar Agente"}
            autoComplete="nope"
            visible={open}
            style={{width: '50vw'}}
            footer={renderFooterCreate}
            onHide={() => onClose(false)}
        >
            <div className="p-fluid">
                <div className="form-group">
                    <div className="row">
                        <div className={'col-sm-6'}>
                            <label>Nome do Agente
                                <span className="obrigatorioAsterisco"> *</span></label>
                            <InputText
                                id="name"
                                required={true}
                                type="text"
                                style={{width: '100%'}}
                                name="name"
                                value={agentState.name}
                                onChange={onAgentStateChange}
                                className="p-inputtext-sm p-d-block p-mb-1"
                            />
                        </div>
                        <div className={'col-sm-6'}>
                            <label>Local de busca <span className="obrigatorioAsterisco"> *</span></label>
                            <Dropdown id="tribunais"
                                      placeholder={"Selecione o tribunal de busca"}
                                      value={agentState.tribunal}
                                      options={tribunaisList}
                                      optionLabel="systemName"
                                      filter showClear filterBy="systemName"
                                      onChange={onAgentStateChange}
                                      name={"tribunal"}
                                      style={{height: '37px'}}
                                      className="w-100 p-inputtext-sm p-d-block p-mb-1"

                            />
                        </div>

                    </div>
                </div>
                <div className={'form-group'}>
                    <div className="row">
                    <div className={'col-sm-6'}>
                            <label>Assuntos{" "}
                                <span>
                                <Button icon="pi pi-plus-circle" className="p-button-rounded p-button-text"
                                        tooltip="Clique para cadastrar um novo assunto"
                                        tooltipOptions={{position: 'top'}}
                                        style={{width: '20px', height: '10px', marginTop: "-7px"}}
                                        onClick={() => setNovoAssunto(true)}
                                    
                                    />
                                </span>
                                </label>
                                <MultiSelect
                                    inputId="multiselect"
                                    value={agentState.assuntos}
                                    options={assuntosOptions}
                                    style={{ width: "100%", height: "37px"}}
                                    onChange={onAssuntoChange}
                                    filter
                                    showClear 
                                    filterBy="label"
                                    placeholder="Selecione os assuntos"
                                    optionLabel="label"
                                    id="assuntos"
                                />
                        </div>
                        <div className={'col-sm-6'}>
                            <label>Credenciais de Acesso<span className="obrigatorioAsterisco"> *</span></label>
                            <Dropdown
                                id="credenciais"
                                type="select"
                                style={{height: '37px'}}
                                options={credenciaisList}
                                optionLabel="usuario.nome"
                                filter showClear filterBy="usuario.nome"
                                value={agentState.credential}
                                name={"credential"}
                                onChange={onAgentStateChange}
                                placeholder="Selecione método de acesso"
                                className="w-100 p-inputtext-sm p-d-block p-mb-1"
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className={'col-sm-6'}>
                            <label>Data de distribuição inicial<span className="obrigatorioAsterisco"> *</span></label>
                            <Calendar id="dataDistribuicaoInicio"
                                      dateFormat="dd/mm/yy"
                                      className={'w-100'}
                                      placeholder={"dd/mm/aaaa"}
                                      value={agentState.dataDistribuicaoInicio}
                                      onChange={(e) => setAgentState({
                                          ...agentState,
                                          dataDistribuicaoInicio: e.value
                                      })}
                            />
                        </div>
                        <div className={'col-sm-6'}>
                            <label>Data de distribuição final<span className="obrigatorioAsterisco"> *</span></label>
                            <Calendar id="dataDistribuicaoFim"
                                      dateFormat="dd/mm/yy"
                                      className={'w-100'}
                                      placeholder={"dd/mm/aaaa"}
                                      value={agentState.dataDistribuicaoFim}
                                      onChange={(e) => setAgentState({
                                          ...agentState,
                                          dataDistribuicaoFim: e.value
                                      })}
                            />
                        </div>
                    </div>
                </div>
                <div className={'form-group'}>
                    <div className="row">
                        <div className={'col-sm-6'}>
                            <label>Polo ativo</label>
                            <InputText id="poloAtivo"
                                       className={'w-100'}
                                       placeholder={"Polo ativo"}
                                       value={agentState.poloAtivo}
                                       onChange={(e) => setAgentState({
                                           ...agentState,
                                           poloAtivo: e.target.value
                                       })}
                            />
                        </div>
                        <div className={'col-sm-6'}>
                            <label>Polo passivo</label>
                            <InputText id="poloPassivo"
                                       className={'w-100'}
                                       placeholder={"Polo passivo"}
                                       value={agentState.poloPassivo}
                                       onChange={(e) => setAgentState({
                                           ...agentState,
                                           poloPassivo: e.target.value
                                       })}
                            />
                        </div>
                    </div>
                </div>
                <div className={'form-group'}>
                    <div className="row">
                        <div className={'col-sm-6'}>
                            <label>Valor da causa</label>
                            <InputNumber id="valorCausa"
                                         placeholder={"0.00"}
                                         className={'w-100'}
                                         value={agentState.valorCausa}
                                         onChange={(e) => setAgentState({
                                             ...agentState,
                                             valorCausa: e.value
                                         })}
                            />
                        </div>
                        <div className={'col-sm-6'}>
                            <label>Intervalo de busca</label>
                            <Dropdown id="intervaloBusca"
                                      style={{height: '37px'}}
                                      placeholder={"Mensalmente"}
                                      options={[
                                          {label: 'Único', value: 0},
                                          {label: 'Diariamente', value: 1},
                                          {label: 'Semanalmente', value: 7},
                                          {label: 'Quinzenalmente ', value: 15},
                                          {label: 'Mensalmente', value: 30},]}
                                      className={'w-100 p-inputtext-sm p-d-block p-mb-1'}
                                      value={agentState.intervaloBusca}
                                      onChange={handleIntervaloBuscaChange}
                            />
                        </div> 
                    </div>
                </div>
                <div className={'form-group'}>
                    <div className="row">
                        <div className="col-sm-6">
                            <label> Buscar precatórios do processo</label>
                            <InputSwitch
                                id="findPrecatorio"
                                checked={agentState.findPrecatorios === 1}  
                                onChange={(e) => {
                                setAgentState({
                                    ...agentState,
                                    findPrecatorios: e.value ? 1 : 0
                                })
                                }}
                                />
                            
                        </div>
                        <div className="col-sm-6">
                            <label>Hora da execução</label>
                            <Dropdown
                                disabled={isHoraExecucaoDisabled}
                                id="intervaloBusca"
                                style={{ height: '37px' }}
                                placeholder="00:00"
                                options={Array.from({ length: 24 }, (_, hour) => {
                                    const formattedHour = hour.toString().padStart(2, '0') + ':00';
                                    return { label: formattedHour, value: formattedHour };
                                })}
                                className="w-100 p-inputtext-sm p-d-block p-mb-1"
                                value={agentState.horaExecucao}
                                onChange={(e) => setAgentState({
                                    ...agentState,
                                    horaExecucao: e.value
                                })}
                            />
                        </div>

                    </div>
                </div>
            </div>
            
            {novoAssunto &&
                <DialogAssuntoAgente open={novoAssunto}
                                     close={() => setNovoAssunto(false)}
                                     toast={toast} refreshList={consultarAssuntos}/>
                        }
        </Dialog>
        
    );
};


