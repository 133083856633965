import React from 'react'

import { withRouter } from 'react-router-dom'
import { AuthContext } from '../../main/ProvedorAutenticacao'
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Calendar } from 'primereact/calendar'
import { ButtonSm } from '../../componentes/ButtonSm';
import { strings } from '../../utils/strings'

import NavBar from '../../componentes/Navbar'
import CentralService from '../../app/service/CentralService'
import AtividadeService from '../../app/service/AtividadeService'
import StatusAtividadeService from '../../app/service/StatusAtividadeService'

import './central.css'
import { DateFormat } from "../../utils/DateFormat";
import { Loading } from "../../componentes/Loading";
import { DialogAlterarAtividade } from "../../componentes/Atividade/DialogAlterarAtividade";

class CentralAtividades extends React.Component {
    constructor(props) {
        super(props);

        this.centralService = new CentralService();
        this.atividadeService = new AtividadeService();
        this.statusAtividadeService = new StatusAtividadeService();

        this.acoesAtividade = this.acoesAtividade.bind(this);
        this.acoesDescricao = this.acoesDescricao.bind(this);
        this.acoesData = this.acoesData.bind(this);

        this.state = {
            central: [],
            dataInicial: '',
            dataFinal: '',
            selectNotificacoes: null,
            //Alterar Atividade
            displayAtividade: false,
            atividade: null,
            statusAtividade: [],
            detail: '',
            openSpinner: false
        };



    }

    listarStatusAtividade = () => {
        const usuarioLogado = this.context.usuarioAutenticado;
        this.statusAtividadeService.listarStatus(usuarioLogado.empresa.codigo)
            .then(response => {
                this.setState({ statusAtividade: response.data });
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Status de atividade',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            });
    }

    consultarCentral = async () => {
        if((this.state.dataFinal.getTime()-this.state.dataInicial.getTime())/1000/60/60/24>90){
            this.toast.show({
                severity: 'error',
                summary: 'Central de notificações',
                detail: `O período máximo do filtro é 90 dias`,
                life: 4000
            });
            return
        }
        const usuarioLogado = this.context.usuarioAutenticado;
        //Data inicial
        var dataInicial = DateFormat(this.state.dataInicial);
        //Data final
        var dataFinal = DateFormat(this.state.dataFinal);

        // this.setState({ openSpinner: true })
        this.context.setLoading(true);
        await this.centralService.consultarCentral(usuarioLogado.codigo, dataInicial, dataFinal)
            .then(response => {
                this.setState({ central: response.data });
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Central de notificações',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            })
        // this.setState({ openSpinner: false })
        this.context.setLoading(false);

    }

    lerNotificacao = async (rowData) => {
        const central = {
            codigo: rowData.codigo,
            status: 2
        }
      await this.centralService.lerNotificacao(central)
            .then(async () => {
                await this.consultarCentral();
            }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Central de notificações',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            })
    }


    mudarStatusNotificacao = async (status) => {
        if (this.state.selectNotificacoes === null || this.state.selectNotificacoes === '') {
            this.toast.show({
                severity: 'error',
                summary: 'Central de notificações',
                detail: 'Nenhuma notificação foi selecionada',
                life: 4000
            });
            return false;
        }
        this.centralService.updateVariasNotificacoes(this.state.selectNotificacoes, status)
            .then(
                async () => {
                    await this.consultarCentral();
                    this.toast.show({
                        severity: 'success',
                        summary: 'Central de notificações',
                        detail: 'Operação realizada com sucesso',
                        life: 4000
                    });

                    this.setState({ selectNotificacoes: null })
                }
            )
            .catch(() => {
                this.toast.show({
                    severity: 'error',
                    summary: 'Central de notificações',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            })
    }

    excluirNotificacoes = async () => {
        if (this.state.selectNotificacoes === null || this.state.selectNotificacoes === '') {
            this.toast.show({
                severity: 'error',
                summary: 'Central de notificações',
                detail: 'Nenhuma notificação foi selecionada',
                life: 4000
            });
            return false;
        }

        var codigos = [];
        for (let i = 0; i < this.state.selectNotificacoes.length; i++) {
            codigos.push(this.state.selectNotificacoes[i].codigo);
        }
        await this.centralService.excluirNotificacoes(codigos)
            .then(response => {
                this.toast.show({
                    severity: 'success',
                    summary: 'Central de notificações',
                    detail: 'Notificação(s) excluída(s) com sucesso',
                    life: 4000
                });
                this.consultarCentral();
            }).catch(error => {
                console.log(error);
                this.setState(error.response.data);
                this.toast.show({
                    severity: 'error',
                    summary: 'Central de notificações',
                    detail: `${this.state.detail}`,
                    life: 4000
                });
            })
    }

    acoesAtividade(rowData) {
        if (rowData.atividade !== null) {
            return (

                <span className={rowData.naoLida}
                    // onClick={()=>this.consultarAtividade(rowData)}
                    style={{ cursor: 'pointer' }}>{rowData.atividade.titulo}</span>
            );
        }

        else if (rowData.processo !== null) {
            return (
                <span className={rowData.naoLida}
                    // onClick={()=>this.consultarAtividade(rowData)}
                    style={{ cursor: 'pointer' }}>{rowData.processo.nrCnj}</span>
            );
        }

        else if (rowData.agente !== null) {
            return (
                <span className={rowData.naoLida}
                    style={{ cursor: 'pointer' }}>{rowData.agente.nome}</span>
            );
        }

        else {
            return (
                <span className={rowData.naoLida}
                    // onClick={()=>this.consultarAtividade(rowData)}
                    style={{ cursor: 'pointer' }}>{"Sem titulo"}</span>
            );
        }
    }

    acoesDescricao(rowData) {
        return (
            <span
                // onClick={()=>this.consultarAtividade(rowData)}
                className={rowData.naoLida}>{rowData.descricao}</span>
        );
    }

    acoesData(rowData) {
        return (
            <span
                // onClick={()=>this.consultarAtividade(rowData)}
                className={rowData.naoLida}>{rowData.dataRegistro}</span>
        );
    }

    pad2 = (n) => {
        return (n < 10 ? '0' : '') + n;
    }

    async componentDidMount() {
        //Datas últimos trinsta dias
        var date = new Date();
        var primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
        var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        //Data inicial
        var dataInicial = DateFormat(primeiroDia);
        //Data final
        var dataFinal = DateFormat(ultimoDia);

        var dataInicial2 = new Date(dataInicial);
        dataInicial2.setDate(dataInicial2.getDate() + 1)

        var dataFinal2 = new Date(dataFinal);
        dataFinal2.setDate(dataFinal2.getDate() + 1)

        await this.setState({ dataInicial: dataInicial2 });
        await this.setState({ dataFinal: dataFinal2 });

        await this.consultarCentral(dataInicial, dataFinal);
        this.listarStatusAtividade()
    }

    refreshPage = () => {
        window.location.reload();
    }

    consultar = (data) => {
        if (data.atividade !== null) {
            this.consultarAtividade(data)
        } else if (data.processo !== null) {
            this.consultarProcesso(data)
        } else {
            // this.toast.show({
            //     severity: 'error',
            //     summary: 'Central de notificações',
            //     detail: `Atividade ou Processo não encontrado`,
            //     life: 4000
            // });
        }

    }

    async consultarAtividade(atividade) {
        let usuario = this.context.usuarioAutenticado
        // this.setState({ openSpinner: true });
        //adicionando verificaçao de usuario para redirecionar a tela e suporte
        if (atividade.atividade.subGrupo.codigo === 2 && usuario.codigo === atividade.atividade.usuario.codigo) {
            await this.lerNotificacao(atividade)
            this.props.history.push('/suporte/' + atividade.atividade.codigo)
            return
        }
        await this.atividadeService.consultarAtividade(atividade.atividade.codigo)
            .then(async (response) => {
                this.setState({ atividade: response.data });
                // await this.setState({ openSpinner: false });
                await this.lerNotificacao(atividade).finally(()=>{
                    this.setState({displayAtividade: true})
                })
                //abre o modal atividade
                // this.setState({ displayAtividade: true })
            }).catch((error) => {
                let detail = error.response.data.detail
                this.toast.show({
                    severity: 'error',
                    summary: 'Central de notificações',
                    detail: `${detail}`,
                    life: 4000
                });
            })
        //abre o modal atividade

    }

    consultarProcesso = async (data) => {
        await this.lerNotificacao(data)
        this.props.history.push(`fichaProcesso/${data.processo.codigo}`)
    }

    render() {
        return (
            <>
                <NavBar />
                <Toast ref={(el) => this.toast = el} />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary card-outline">
                                        <div className="card-header">
                                            <h3 className="card-title">
                                                <i className="pi pi-comments"></i>
                                                <span> Central de notificações</span>
                                            </h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12 flex-1 gap-1">
                                                    <ButtonSm
                                                        className={strings.buttonPrimarySm}
                                                        aria-label="Submit"
                                                        title="Marcar como lida"
                                                        onClick={() => this.mudarStatusNotificacao(2)}>
                                                        <i className="pi pi-check"></i> Lida
                                                    </ButtonSm>
                                                    <ButtonSm
                                                        className={strings.buttonSecondarySm}
                                                        aria-label="Bookmark"
                                                        title="Voltar como não lida"
                                                        onClick={() => this.mudarStatusNotificacao(1)}>
                                                        <i className={"pi pi-bookmark"}></i> Não lida
                                                    </ButtonSm>
                                                    <ButtonSm
                                                        className={strings.buttonDangerSm}
                                                        aria-label="Cancel"
                                                        title="Excluir"
                                                        onClick={this.excluirNotificacoes}>
                                                        <i className={"pi pi-times"}></i> Excluir
                                                    </ButtonSm>
                                                    <Calendar id="mask"
                                                        value={this.state.dataInicial}
                                                        onChange={(e) => this.setState({ dataInicial: e.value })}
                                                        locale="es"
                                                        style={{ width: '15%', height: '37px' }}
                                                        dateFormat="dd/mm/yy"
                                                        placeholder="Data Inicial"
                                                        mask="99/99/9999" />
                                                    <Calendar id="mask"
                                                        value={this.state.dataFinal}
                                                        onChange={(e) => this.setState({ dataFinal: e.value })}
                                                        locale="es"
                                                        style={{ width: '15%', height: '37px' }}
                                                        dateFormat="dd/mm/yy"
                                                        placeholder="Data Final"
                                                        mask="99/99/9999" />
                                                    <ButtonSm style={{ height: '37px' }} type="button"
                                                        className={strings.buttonSecondarySm}
                                                        onClick={this.consultarCentral}>
                                                        <i className="pi pi-filter"></i>Filtrar</ButtonSm>
                                                </div>
                                            </div>
                                        </div>

                                        <DataTable value={this.state.central} paginator responsiveLayout="scroll"
                                            selection={this.state.selectNotificacoes}
                                            selectionMode={'checkbox'}
                                            onSelectionChange={(e) => this.setState({ selectNotificacoes: e.value })}
                                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                            emptyMessage="Nenhum notificação"
                                            currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                                            rows={10}
                                            onRowClick={(row) => this.consultar(row.data)}
                                        >
                                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}
                                            ></Column>

                                            <Column body={this.acoesAtividade} exportable={false}
                                                style={{ minWidth: '8rem' }} header="Título"></Column>
                                            <Column body={this.acoesDescricao} exportable={false}
                                                style={{ minWidth: '8rem' }} header="Descrição"></Column>
                                            <Column body={this.acoesData} exportable={false} style={{ minWidth: '8rem' }}
                                                header="Data"></Column>
                                        </DataTable>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/**Consultar Atividade */}
                        {this.state.displayAtividade &&
                            <DialogAlterarAtividade
                                isOpen={this.state.displayAtividade}
                                onClose={() => this.setState({ displayAtividade: false })}
                                data={this.state.atividade}
                                toast={this.toast}
                                refresh={this.refreshPage}
                                statusAtiv={this.state.statusAtividade}
                            />}
                    </section>
                </div>
                {/*<Loading open={this.state.openSpinner} />*/}
            </>
        )
    }
}

CentralAtividades.contextType = AuthContext;
export default withRouter(CentralAtividades)

