import ApiService from '../apiservice'

export default class ProcessoService extends ApiService {

  constructor() {
    super('/processos');
  }

  cadastrarProcesso(processo) {
    return this.post('', processo);
  }

  listarProcessos(filtro) {
    let params = `?empresa=${filtro.empresa.codigo}&usuario=${filtro.usuario}`;

    if(filtro.pessoa !== '') {
      params = `${params}&pessoa=${filtro.pessoa}`;
    }

    if(filtro.grupoTrabalho !== null) {
      params = `${params}&grupoTrabalho=${filtro.grupoTrabalho.codigo}`;
    }

    if(filtro.tipoAcao !== null) {
      params = `${params}&tipoAcao=${filtro.tipoAcao.codigo}`;
    }

    if(filtro.statusProcessual !== null) {
      params = `${params}&statusProcessual=${filtro.statusProcessual.codigo}`;
    }

    if(filtro.tipoDecisao !== null) {
      params = `${params}&tipoDecisao=${filtro.tipoDecisao.codigo}`;
    }

    if(filtro.pasta !== '') {
      params = `${params}&pasta=${filtro.pasta}`;
    }

    if(filtro.numeroProcesso !== '') {
      params = `${params}&numeroProcesso=${filtro.numeroProcesso}`;
    }

    if(filtro.numeroCnj !== '') {
      params = `${params}&numeroCnj=${filtro.numeroCnj}`;
    }

    if(filtro.importanteParaMim === 'SIM') {
      params = `${params}&importanteParaMim=${filtro.importanteParaMim}`
    }

    return this.get(params);
  }

  // alterarProcesso(processo) {
  //   return this.put(`/${processo.codigo}`,processo);
  // }
  alterarProcesso(processoDTO) {
    return this.put(`/alterar`,processoDTO);
  }

  consultarProcessoPorCodigo(codigo) {
    return this.get(`/${codigo}`);
  }
  consultarFichaProcessoPorCodigo(codigo) {
    return this.get(`/ficha/${codigo}`);
  }

  cadastrarPartes(parte) {
    return this.post(`/partes`,parte);
  }

  excluirIndiceProcesso(processo) {
    return this.delete(`/indice/${processo}`);
  }

  cadastrarIndiceProcesso(processo) {
    return this.post('/indice', processo);
  }

  consultarProcessoPorIndice(indice) {
    return this.get(`/porIndice?indice=${indice.indice}&empresa=${indice.empresa}&usuario=${indice.usuario}`);
  }

  consultarProcessoQueryLike(indice) {
    const url = `/search?termo=${indice.indice}&cdEmpresa=${indice.empresa}`
    return this.get(url);
  }

  listarPartes(parte) {
    return this.get(`/partesTipo?processo=${parte.processo}&tipo=${parte.tipo}`);
  }

  listarPartesProcessoTipo(filtro) {
    return this.get(`/partesTipo?processo=${filtro.processo}&tipo=${filtro.tipo}`);
  }

  excluirParte(codigo) {
    return this.delete(`/partes/${codigo}`);
  }

  alterarImportanciaEmpresa(processo) {
    return this.put(`/importancia/${processo.codigo}`,processo);
  }

  excluirProcesso(processo) {
    return this.put(`/excluirProcesso/${processo.codigo}`, processo);
  }

  consultarLancamentosFinanceiros(processo){
    return this.get(`/lancamentosFinanceiros/${processo}`);
  }

  consultarProcessosExcluidos(empresa) {
    return this.get(`/processosExcluidos/${empresa}`);
  }

  ativarProcesso(processo) {
    return this.put(`/ativarProcesso/${processo.codigo}`, processo);
  }
}
