import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { Button } from "primereact/button";
import AssuntoAgenteService from "../app/service/AgenteAssuntoService";
import { strings } from "../utils/strings";

export const DialogAssuntoAgente = ({ open, toast, close, refreshList }) => {
  const [state, setState] = useState({
    assuntoAgenteService: new AssuntoAgenteService(),
    assunto: "",
    openSpinner: false,
    classeDiv: "hide",
  });

  const limparCampos = () => {
    setState({ assunto: ""});
  };

  const cadastrarGrupo = async () => {
    setState({ openSpinner: true });

    if (!state.assunto) {
      toast.show({
        severity: "error",
        summary: "Assunto",
        detail: "Informe o Assunto",
        life: 90000,
      });
      stateChange("classeDiv", "hide");
      return false;
    }
    await state.assuntoAgenteService
      .createAssunto({assunto: state.assunto})
      .then((response) => {
        toast.show({
          severity: "success",
          summary: "Assunto",
          detail: "Assunto cadastrado com sucesso",
          life: 4000,
        });
        refreshList();
        limparCampos();
        close();
      })
      .catch((error) => {
        stateChange("detail", error.response.data.detail);
        toast.show({
          severity: "error",
          summary: "Assunto",
          detail: `${error.response.data.detail}`,
          life: 4000,
        });
      });

    stateChange("openSpinner", false);
  };

  function stateChange(name, value) {
    setState({
      ...state,
      [`${name}`]: value,
    });
  }

  function renderFooterCadastroGrupos() {
    return (
      <div className="row-etrium gap-1">
        <Button
          label="Cadastrar"
          className={strings.buttonPrimaryStyle}
          onClick={cadastrarGrupo}
        />
      </div>
    );
  }

  return (
    <Dialog
      header="Cadastrar Assunto"
      visible={open}
      style={{ width: "40vw" }}
      footer={() => renderFooterCadastroGrupos()}
      onHide={close}
    >
      <div className="p-fluid">
        <div className="p-field mb-3">
          <label htmlFor="assunto">
            Assunto<span className="obrigatorioAsterisco"> *</span>
          </label>
          <InputText
            id="assunto"
            type="text"
            maxLength={50}
            value={state.assunto}
            onChange={(e) => stateChange("assunto", e.target.value)}
          />
        </div>
      </div>
    </Dialog>
  );
};
