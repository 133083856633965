import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import AgenteService from "../../app/service/AgenteService";
import ProcessoAutomaticoService from "../../app/service/ProcessoAutomaticoService";
import Navbar from "../../componentes/Navbar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { Loading } from "../../componentes/Loading";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { strings } from "../../utils/strings";
import { formatarHoraAgente, formatDateAgent } from "../../utils/DateFormat";
import { formatarNumeroParaReais } from "../../utils/NumberFormat";

function DetalhesAgente() {
  const { id } = useParams();
  const [agente, setAgente] = useState(null);
  const [nome, setNome] = useState("");
  const [tribunal, setTribunal] = useState("");
  const [credencial, setCredencial] = useState("");
  const [dataDistribuicaoInicio, setDataDistribuicaoInicio] = useState("");
  const [dataDistribuicaoFim, setDataDistribuicaoFim] = useState("");
  const [valorCausa, setValorCausa] = useState(0);
  const [intervaloBusca, setIntervaloBusca] = useState(0);
  const [poloAtivo, setPoloAtivo] = useState("");
  const [poloPassivo, setPoloPassivo] = useState("");
  const [findPrecatorios, setFindPrecatorios] = useState(false);
  const [status, setStatus] = useState("");
  const [horaExecucao, setHoraExecucao] = useState("");
  const [assuntos, setAssuntos] = useState("");
  const [loading, setLoading] = useState(false);
  const [errosAgente, setErrosAgente] = useState({ erros: [], totalErros: 0 });
  const [processosRetornados, setProcessosRetornados] = useState([]);
  const [tribunaisList, setTribunaisList] = useState([]);
  const [intervaloBuscaOptions, setIntervaloBuscaOptions] = useState([]);

  const agenteService = new AgenteService();
  const processoAutomaticoService = new ProcessoAutomaticoService();
  const toast = useRef(null);

  const statusOptions = [
    { label: "Operante", value: "Operante" },
    { label: "Inoperante", value: "Inoperante" },
  ];

  const precatoriosOptions = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ];

  const baseIntervaloBuscaOptions = [
    { label: "Diariamente", value: 1 },
    { label: "Semanalmente", value: 7 },
    { label: "Quinzenalmente", value: 15 },
    { label: "Mensalmente", value: 30 },
  ];

  const getTribunalNameById = (id) => {
    const tribunal = tribunaisList.find((tribunal) => tribunal.codigo === id);
    return tribunal ? tribunal.systemName : "Tribunal não encontrado";
  };

  const parseNumber = (value) => {
    if (!value || value === "R$") return 0;
    const cleanValue = value
      .replace("R$", "")
      .replace(/\s/g, "")
      .replace(/\./g, "")
      .replace(",", ".");
    const numberValue = parseFloat(cleanValue);
    return isNaN(numberValue) ? 0 : numberValue;
  };

  const groupProcessosByUpdateDate = (processos) => {
    const grouped = processos.reduce((acc, processo) => {
      const updateDate = formatDateAgent(processo.updateDate.split(" ")[0]);
      if (!acc[updateDate]) {
        acc[updateDate] = [];
      }
      acc[updateDate].push(processo);
      return acc;
    }, {});
    return grouped;
  };

  useEffect(() => {
    setLoading(true);

    processoAutomaticoService
      .getSistemasWithCertified()
      .then((res) => {
        setTribunaisList(res.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar tribunais:", error);
      });

    agenteService
      .getAgenteDetalhadoById(id)
      .then((res) => {
        const data = res.data;
        setAgente(data.agente);
        setNome(data.agente.nome || "");
        setTribunal(data.agente.tribunal || "");
        setCredencial(data.agente.credencial || "");
        setDataDistribuicaoInicio(data.agente.dataDistribuicaoInicio || "");
        setDataDistribuicaoFim(data.agente.dataDistribuicaoFim || "");
        setValorCausa(data.agente.valorCausa || 0);
        setIntervaloBusca(data.agente.intervaloBusca || 0);
        setPoloAtivo(data.agente.poloAtivo || "");
        setPoloPassivo(data.agente.poloPassivo || "");
        setFindPrecatorios(data.agente.findPrecatorios || false);
        setStatus(data.agente.status || "");
        setHoraExecucao(formatarHoraAgente(data.agente.horaExecucao || ""));
        setAssuntos(data.agente.assuntos ? data.agente.assuntos.join(", ") : "");
        setErrosAgente(data.agenteErros);
        setProcessosRetornados(data.processosRetornados || []);

        if (data.agente.intervaloBusca === 0) {
          setIntervaloBuscaOptions([{ label: "Único", value: 0 }, ...baseIntervaloBuscaOptions]);
        } else {
          setIntervaloBuscaOptions(baseIntervaloBuscaOptions);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao carregar os dados do agente", error);
        setLoading(false);
      });
  }, [id]);

  const salvarAlteracoes = () => {
    const assuntosArray = assuntos ? assuntos.split("\n").filter((item) => item.trim() !== "") : [];

    const agenteAtualizado = {
      codigo: Number(agente.codigo),
      nome: agente.nome,
      tribunal: Number(agente.tribunal),
      credencial: Number(agente.credencial),
      dataDistribuicaoInicio: dataDistribuicaoInicio || null,
      dataDistribuicaoFim: dataDistribuicaoFim || null,
      valorCausa: valorCausa,
      intervaloBusca: Number(intervaloBusca),
      poloAtivo: poloAtivo || null,
      poloPassivo: poloPassivo || null,
      empresa: Number(agente.empresa),
      assuntos: assuntosArray,
      findPrecatorios: findPrecatorios,
      status: status || null,
      horaExecucao: horaExecucao ? `${horaExecucao}:00` : null,
    };

    agenteService
      .updateAgente(agenteAtualizado.codigo, agenteAtualizado)
      .then((response) => {
        setAgente(response.data);
        toast.current.show({
          severity: "success",
          summary: "Edição de agente",
          detail: "Agente alterado com sucesso!",
          life: 4000,
        });
      })
      .catch((error) => {
        console.error("Erro ao atualizar o agente", error);
        toast.current.show({
          severity: "error",
          summary: "Erro",
          detail: "Ocorreu um erro ao atualizar o agente.",
          life: 4000,
        });
      });
  };

  if (!agente) {
    return <Loading open={true} />;
  }

  const processosAgrupados = groupProcessosByUpdateDate(processosRetornados);

  return (
    <>
      <Navbar />
      <Toast ref={toast} />
      <Loading open={loading} />
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary card-outline">
                  <div className="card-header">
                    <h3 className="card-title">
                      <i className="pi pi-user"></i>
                      <span> Alterar Agente</span>
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      {/* Informações Gerais */}
                      <div className="col-md-6">
                        <h4>Informações Gerais</h4>
                        <div className="form-group">
                          <label>Código:</label>
                          <InputText
                            type="text"
                            value={agente.codigo}
                            disabled
                            className="p-inputtext-sm p-d-block p-mb-1"
                            style={{ width: "100%", height: "32px" }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Nome:</label>
                          <InputText
                            type="text"
                            value={nome}
                            disabled
                            className="p-inputtext-sm p-d-block p-mb-1"
                            style={{ width: "100%", height: "32px" }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Tribunal:</label>
                          <InputText
                            type="text"
                            value={getTribunalNameById(tribunal)}
                            disabled
                            className="p-inputtext-sm p-d-block p-mb-1"
                            style={{ width: "100%", height: "32px" }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Credencial:</label>
                          <InputText
                            type="text"
                            value={credencial}
                            disabled
                            className="p-inputtext-sm p-d-block p-mb-1"
                            style={{ width: "100%", height: "32px" }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Status:</label>
                          <Dropdown
                            disabled
                            value={status}
                            options={statusOptions}
                            onChange={(e) => setStatus(e.value)}
                            className="p-inputtext-sm p-d-block p-mb-1"
                            style={{ width: "100%", height: "32px" }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Intervalo de Busca:</label>
                          <Dropdown
                            id="intervaloBusca"
                            style={{ height: "37px" }}
                            placeholder="Selecione o intervalo"
                            options={intervaloBuscaOptions}
                            className="w-100 p-inputtext-sm p-d-block p-mb-1"
                            value={intervaloBusca}
                            onChange={(e) => setIntervaloBusca(e.value)}
                            disabled={intervaloBusca === 0}
                          />
                        </div>
                        <div className="form-group">
                          <label>Hora de Execução:</label>
                          <Dropdown
                            style={{ height: "37px" }}
                            placeholder="00:00"
                            options={Array.from({ length: 24 }, (_, hour) => {
                              const formattedHour = hour.toString().padStart(2, "0") + ":00";
                              return { label: formattedHour, value: formattedHour };
                            })}
                            className="w-100 p-inputtext-sm p-d-block p-mb-1"
                            value={horaExecucao}
                            onChange={(e) => setHoraExecucao(e.value)}
                            disabled={intervaloBusca === 0}
                          />
                        </div>
                      </div>
                      {/* Detalhes do Processo */}
                      <div className="col-md-6">
                        <h4>Detalhes do Processo</h4>
                        <div className="form-group">
                          <label>Data Distribuição Início:</label>
                          <InputText
                            type="text"
                            value={formatDateAgent(dataDistribuicaoInicio)}
                            onChange={(e) => setDataDistribuicaoInicio(e.target.value)}
                            className="p-inputtext-sm p-d-block p-mb-1"
                            style={{ width: "100%", height: "32px" }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Data Distribuição Fim:</label>
                          <InputText
                            type="text"
                            value={formatDateAgent(dataDistribuicaoFim)}
                            onChange={(e) => setDataDistribuicaoFim(e.target.value)}
                            className="p-inputtext-sm p-d-block p-mb-1"
                            style={{ width: "100%", height: "32px" }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Valor Causa:</label>
                          <InputText
                            disabled
                            type="text"
                            value={formatarNumeroParaReais(valorCausa)}
                            onChange={(e) => setValorCausa(parseNumber(e.target.value))}
                            className="p-inputtext-sm p-d-block p-mb-1"
                            style={{ width: "100%", height: "32px" }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Polo Ativo:</label>
                          <InputText
                            type="text"
                            value={poloAtivo}
                            onChange={(e) => setPoloAtivo(e.target.value)}
                            className="p-inputtext-sm p-d-block p-mb-1"
                            style={{ width: "100%", height: "32px" }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Polo Passivo:</label>
                          <InputText
                            type="text"
                            value={poloPassivo}
                            onChange={(e) => setPoloPassivo(e.target.value)}
                            className="p-inputtext-sm p-d-block p-mb-1"
                            style={{ width: "100%", height: "32px" }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Busca Precatórios:</label>
                          <Dropdown
                            value={findPrecatorios}
                            options={precatoriosOptions}
                            onChange={(e) => setFindPrecatorios(e.value)}
                            className="p-inputtext-sm p-d-block p-mb-1"
                            style={{ width: "100%", height: "32px" }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Assuntos:</label>
                          <InputTextarea
                            disabled
                            value={assuntos}
                            onChange={(e) => setAssuntos(e.target.value)}
                            className="p-inputtext-sm p-d-block p-mb-1"
                            style={{ width: "100%", minHeight: "100px" }}
                            placeholder="Assuntos"
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    {/* Seção de Processos Retornados */}
                    <div className="row">
                      <div className="col-md-12">
                        <h4>Processos Retornados</h4>
                        <div className="form-group">
                          <label>Total de Processos Retornados:</label>
                          <InputText
                            type="text"
                            value={processosRetornados.length}
                            disabled
                            className="p-inputtext-sm p-d-block p-mb-1"
                            style={{ width: "100%", height: "32px" }}
                          />
                        </div>
                        {Object.keys(processosAgrupados).length > 0 ? (
                          <div>
                            {Object.keys(processosAgrupados)
                              .sort((a, b) => new Date(b) - new Date(a))
                              .map((data) => (
                                <div key={data}>
                                  <h5>{data}</h5>
                                  <ul>
                                    {processosAgrupados[data].map((processo, index) => (
                                      <>
                                        <li key={index}>
                                          <strong>CNJ:</strong> {processo.cnj} <br />
                                          <strong>Status:</strong> {processo.status} <br />
                                          <strong>Local de Tramitação:</strong> {processo.localTramite} <br />
                                          <strong>Data de Distribuição:</strong> {processo.distributionDate} <br />
                                          <strong>Eventos:</strong> {processo.events}
                                        </li>
                                        <br />
                                      </>
                                    ))}
                                  </ul>
                                </div>
                              ))}
                          </div>
                        ) : (
                          <p>Sem processos retornados.</p>
                        )}
                      </div>
                    </div>
                    <hr />
                    {/* Seção de Erros do Agente */}
                    <div className="row">
                      <div className="col-md-12">
                        <h4>Erros do Agente</h4>
                        <div className="form-group">
                          <label>Total de Erros:</label>
                          <InputText
                            type="text"
                            value={errosAgente.totalErros}
                            disabled
                            className="p-inputtext-sm p-d-block p-mb-1"
                            style={{ width: "100%", height: "32px" }}
                          />
                        </div>
                        {errosAgente.erros.length > 0 ? (
                          <ul>
                            {errosAgente.erros.map((erroObj, index) => (
                              <>
                                <li key={index}>
                                  <strong>{erroObj.dtRegistro}:</strong> {erroObj.erro}
                                </li>
                                <br />
                              </>
                            ))}
                          </ul>
                        ) : (
                          <p>Sem erros registrados.</p>
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12">
                        <Button
                          label="Alterar"
                          onClick={salvarAlteracoes}
                          className={strings.buttonPrimarySm}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default withRouter(DetalhesAgente);