import {useContext, useEffect, useState} from "react";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";
import {ButtonEtrium} from "../../ButtonEtrium";
import {InputNumber} from "primereact/inputnumber";
import {Dropdown} from "primereact/dropdown";
import {IsNullOrEmpty} from "../../../utils/IsNullOrEmpy";
import ProcessoAutomaticoService from "../../../app/service/ProcessoAutomaticoService";
import {AuthContext} from "../../../main/ProvedorAutenticacao";
import {DateFormat} from "../../../utils/DateFormat";
import AgenteService from "../../../app/service/AgenteService";
import AgenteAssuntoService from "../../../app/service/AgenteAssuntoService";
import { Button } from "primereact/button";
import { DialogAssuntoAgente } from "../../DialogAssuntoAgente";
import { MultiSelect } from "primereact/multiselect";
import { InputSwitch } from "primereact/inputswitch";

export const AgentUpdateDialog = ({open, onClose, onConfirm, toast, agenteId}) => {

    const [agentState, setAgentState] = useState({
        nome: "",
        tribunal: null,
        credencial: null,
        dataDistribuicaoInicio: "",
        dataDistribuicaoFim: "",
        valorCausa: 0,
        intervaloBusca: 30, //numero de dias
        poloAtivo: "",
        poloPassivo: "",
        assuntos: [],
        findPrecatorios: 0,
        horaExecucao: "00:00"
    })
    const [credenciaisList, setCredenciaisList] = useState([]);
    const [tribunaisList, setTibunaisList] = useState([]);
    const [assuntosOptions, setAssuntosOptions] = useState([]);
    const [novoAssunto, setNovoAssunto] = useState(false)
    const {usuarioAutenticado, setLoading} = useContext(AuthContext);
    const [intervaloBuscaOptions, setIntervaloBuscaOptions] = useState([]);
    const service = new ProcessoAutomaticoService()
    const agenteService = new AgenteService()
    const agenteAssuntoService = new AgenteAssuntoService()

    const isIntervaloUnico = agentState.intervaloBusca === 0;
    

    const onAgentStateChange = (e) => {
        const {name, value} = e.target;
        setAgentState({
            ...agentState,
            [name]: value
        });
    }

    const onAssuntoChange = (e) => {
        setAgentState({
          ...agentState,
          assuntos: e.value
        });
    };

    const consultarAssuntos = async () => {
        try {
          const response = await agenteAssuntoService.getAssuntosByEmpresa(usuarioAutenticado.empresa.codigo);
          const assuntosArray = response.data.map(item => item.assunto);
          const uniqueAssuntos = [...new Set(assuntosArray)];
          const options = uniqueAssuntos.map(item => ({
            label: item,
            value: item
          }));
          setAssuntosOptions(options);
        } catch (error) {
          toast.show({
            severity: "error",
            summary: "Atividades",
            detail: "Erro ao carregar assuntos",
            life: 4000
          });
        }
      };
      

    const getAgentToUpdate = async () => {
        return await agenteService.getAgenteById(agenteId).then((res) => {
            return res.data
            // setAgenteToState(data)
        })
            .catch((error) => {
                let detail = error.response.data.detail || "Erro ao buscar agente"
                toast.show({severity: 'error', summary: 'Buscar Agente', detail: detail, life: 4000});
            })
    }

    const handleUpdateAgent = () => {
        if (IsNullOrEmpty(agentState.nome)) {
            return toast.show({
                severity: 'error',
                summary: 'Alterar agente',
                detail: 'Nome do agente obrigatório',
                life: 4000
            });
        }
        if (IsNullOrEmpty(agentState.tribunal)) {
            return toast.show({
                severity: 'error',
                summary: 'Alterar agente',
                detail: 'Local de busca é obrigatório',
                life: 4000
            });
        }
        if (IsNullOrEmpty(agentState.credencial)) {
            return toast.show({
                severity: 'error',
                summary: 'Alterar agente',
                detail: 'Credencial de acesso é obrigatória',
                life: 4000
            });
        }

        let dataInicio = null
        let dataFim = null
        if (!IsNullOrEmpty(agentState.dataDistribuicaoInicio)) {
            dataInicio = DateFormat(agentState.dataDistribuicaoInicio)
        }
        if (!IsNullOrEmpty(agentState.dataDistribuicaoFim)) {
            dataFim = DateFormat(agentState.dataDistribuicaoFim)
        }

        let newAgente = {
            nome: agentState.nome,
            tribunal: agentState.tribunal.codigo,
            credencial: agentState.credencial.codigo,
            dataDistribuicaoInicio: dataInicio,
            dataDistribuicaoFim: dataFim,
            valorCausa: agentState.valorCausa,
            intervaloBusca: agentState.intervaloBusca,
            poloAtivo: agentState.poloAtivo,
            poloPassivo: agentState.poloPassivo,
            empresa: usuarioAutenticado.empresa.codigo,
            assuntos: agentState.assuntos,
            findPrecatorios: agentState.findPrecatorios,
            horaExecucao: agentState.horaExecucao
        }
        agenteService.updateAgente(agentState.codigo, newAgente).then(() => {
            toast.show({
                severity: 'success',
                summary: 'Alterar agente',
                detail: 'Agente atualizado com sucesso',
                life: 4000
            });
            onConfirm()
            onClose(false)
        })
            .catch((error) => {
                let detail = error.response.data.detail || "Erro ao alterar agente"
                toast.show({severity: 'error', summary: 'Alterar Agente', detail: detail, life: 4000});
            })
    };


    const renderFooterCreate = () => {
        return (
            <div className={"row-etrium"}>
                <ButtonEtrium label="Alterar" onClick={handleUpdateAgent}/>
            </div>
        );
    };

    const getUsuarios = async () => {
        return await service.getUsuariosComCertificadoPorEmpresa(usuarioAutenticado.empresa.codigo).then((res) => {
            const data = res.data
            setCredenciaisList(data)
            if (data.length > 0) {
                setAgentState({
                    ...agentState,
                    credential: data[0]
                });
            }
            return data
        })
            .catch(
                (error) => {
                    const detail = error.response.data.detail || "Erro ao buscar credenciais"
                    toast.show({
                        severity: 'error',
                        summary: 'Erro',
                        detail: `${detail}`,
                        life: 4000
                    })
                }
            )
    }

    const getTribunais = async () => {
        return await service.getSistemasWithCertified().then((res) => {
            const data = res.data
            if (data && data.length > 0) {
                setTibunaisList([data[0]]);
              } else {
                setTibunaisList([]);
              }
            return data
        })
            .catch(
                (error) => {
                    const detail = error.response.data.detail || "Erro ao buscar tribunais"
                    toast.show({
                        severity: 'error',
                        summary: 'Erro',
                        detail: `${detail}`,
                        life: 4000
                    })
                }
            )
    }

    useEffect(() => {
        let tribunais = null
        let credentials = null
        let agent = null
        setLoading(true)
        consultarAssuntos();
        getUsuarios().then(data => {
            credentials = data
            getTribunais().then((data) => {
                tribunais = data
                getAgentToUpdate().then((data) => {
                    agent = data
                    setAgenteToState(agent, tribunais, credentials)
                })
            })
        }).finally(
            () => {
                setTimeout(() => {
                    setLoading(false)
                }, 500)

            }
        )


    }, []);

    function setAgenteToState(updateAgente, tribunaisList, credenciaisList) {
        if (updateAgente) {
            let _updateAgent = {...updateAgente}
            _updateAgent.dataDistribuicaoInicio = new Date(_updateAgent.dataDistribuicaoInicio + "T00:00:00")
            _updateAgent.dataDistribuicaoFim = new Date(_updateAgent.dataDistribuicaoFim + "T00:00:00")
            _updateAgent.tribunal = tribunaisList.find((tribunal) => tribunal.codigo === _updateAgent.tribunal)
            _updateAgent.credencial = credenciaisList.find((credencial) => credencial.codigo === _updateAgent.credencial)
            if (_updateAgent.horaExecucao) {
                _updateAgent.horaExecucao = _updateAgent.horaExecucao.substring(0, 5);
            } else {
                _updateAgent.horaExecucao = "00:00";
            }
            setAgentState(_updateAgent)

            const baseOptions = [
                { label: "Diariamente", value: 1 },
                { label: "Semanalmente", value: 7 },
                { label: "Quinzenalmente", value: 15 },
                { label: "Mensalmente", value: 30 },
              ];
              if (_updateAgent.intervaloBusca === 0) {
                setIntervaloBuscaOptions([{ label: "Único", value: 0 }, ...baseOptions]);
              } else {
                setIntervaloBuscaOptions(baseOptions);
              }
        }
    }

    return (
        <Dialog
            header={"Alterar agente"}
            autoComplete="nope"
            visible={open}
            style={{width: '50vw'}}
            footer={renderFooterCreate}
            onHide={() => onClose(false)}
        >
            <div className="p-fluid">
                <div className="form-group">
                    <div className="row">
                        <div className={'col-sm-6'}>
                            <label>Nome do Agente
                                <span className="obrigatorioAsterisco"> *</span></label>
                            <InputText
                                id="nome"
                                required={true}
                                type="text"
                                style={{width: '100%'}}
                                name="nome"
                                value={agentState.nome}
                                onChange={onAgentStateChange}
                                className={'w-100 p-inputtext-sm p-d-block p-mb-1'}
                            />
                        </div>
                        <div className={'col-sm-6'}>
                            <label>Local de busca <span className="obrigatorioAsterisco"> *</span></label>
                            <Dropdown id="tribunais"
                                      placeholder={"Selecione o tribunal de busca"}
                                      className={'w-100 p-inputtext-sm p-d-block p-mb-1'}
                                      value={agentState.tribunal}
                                      options={tribunaisList}
                                      optionLabel="systemName"
                                      filter showClear filterBy="systemName"
                                      onChange={onAgentStateChange}
                                      name={"tribunal"}
                                      disabled={isIntervaloUnico}
                            />
                        </div>

                    </div>
                </div>
                <div className={'form-group'}>
                    <div className="row">
                        <div className={'col-sm-6'}>
                            <label>Assuntos{" "}
                                <span>
                                <Button icon="pi pi-plus-circle" className="p-button-rounded p-button-text"
                                        tooltip="Clique para cadastrar um novo assunto"
                                        tooltipOptions={{position: 'top'}}
                                        style={{width: '20px', height: '10px', marginTop: "-7px"}}
                                        onClick={() => setNovoAssunto(true)}
                                        disabled={isIntervaloUnico}
                                    />
                                </span>
                                </label>
                                <MultiSelect
                                    inputId="multiselect"
                                    value={agentState.assuntos}
                                    options={assuntosOptions}
                                    style={{ width: "100%" }}
                                    onChange={onAssuntoChange}
                                    filter
                                    showClear 
                                    filterBy="label"
                                    placeholder="Selecione os assuntos"
                                    optionLabel="label"
                                    
                                    className="w-100 p-inputtext-sm p-d-block p-mb-1"
                                    id="assuntos"
                                    disabled={isIntervaloUnico}
                                />
                        </div>
                        <div className={'col-sm-6'}>
                            <label>Credenciais de Acesso<span className="obrigatorioAsterisco"> *</span></label>
                            <Dropdown
                                id="credenciais"
                                type="select"
                                style={{height: '37px'}}
                                options={credenciaisList}
                                optionLabel="usuario.nome"
                                filter showClear filterBy="usuario.nome"
                                value={agentState.credencial}
                                name={"credencial"}
                                onChange={onAgentStateChange}
                                placeholder="Selecione método de acesso"
                                className="w-100 p-inputtext-sm p-d-block p-mb-1"
                                disabled={isIntervaloUnico}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className={'col-sm-6'}>
                            <label>Data de distribuição inicial</label>
                            <Calendar id="dataDistribuicaoInicio"
                                      dateFormat="dd/mm/yy"
                                      className={'w-100'}
                                      placeholder={"dd/mm/aaaa"}
                                      value={agentState.dataDistribuicaoInicio}
                                      onChange={(e) => setAgentState({
                                          ...agentState,
                                          dataDistribuicaoInicio: e.value
                                      })}
                                      disabled={isIntervaloUnico}
                            />
                        </div>
                        <div className={'col-sm-6'}>
                            <label>Data de distribuição final</label>
                            <Calendar id="dataDistribuicaoFim"
                                      dateFormat="dd/mm/yy"
                                      className={'w-100'}
                                      placeholder={"dd/mm/aaaa"}
                                      value={agentState.dataDistribuicaoFim}
                                      onChange={(e) => setAgentState({
                                          ...agentState,
                                          dataDistribuicaoFim: e.value
                                      })}
                                      disabled={isIntervaloUnico}
                            />
                        </div>
                    </div>
                </div>
                <div className={'form-group'}>
                    <div className="row">
                        <div className={'col-sm-6'}>
                            <label>Polo ativo</label>
                            <InputText id="poloAtivo"
                                       className={'w-100'}
                                       placeholder={"Polo ativo"}
                                       value={agentState.poloAtivo}
                                       onChange={(e) => setAgentState({
                                           ...agentState,
                                           poloAtivo: e.target.value
                                       })}
                                       disabled={isIntervaloUnico}
                            />
                        </div>
                        <div className={'col-sm-6'}>
                            <label>Polo passivo</label>
                            <InputText id="poloPassivo"
                                       className={'w-100'}
                                       placeholder={"Polo passivo"}
                                       value={agentState.poloPassivo}
                                       onChange={(e) => setAgentState({
                                           ...agentState,
                                           poloPassivo: e.target.value
                                       })}
                                       disabled={isIntervaloUnico}
                            />
                        </div>
                    </div>
                </div>
                <div className={'form-group'}>
                    <div className="row">
                        <div className={'col-sm-6'}>
                            <label>Valor da causa</label>
                            <InputNumber id="valorCausa"
                                         placeholder={"0.00"}
                                         className={'w-100'}
                                         value={agentState.valorCausa}
                                         onChange={(e) => setAgentState({
                                             ...agentState,
                                             valorCausa: e.value
                                         })}
                                         disabled={isIntervaloUnico}
                            />
                        </div>
                        <div className={'col-sm-6'}>
                            <label>Intervalo de busca</label>
                            <Dropdown id="intervaloBusca"
                                      placeholder={"Mensalmente"}
                                      options={intervaloBuscaOptions}
                                      className={'w-100 p-inputtext-sm p-d-block p-mb-1'}
                                      value={agentState.intervaloBusca}
                                      onChange={(e) => {
                                          setAgentState({
                                              ...agentState,
                                              intervaloBusca: e.value
                                          })
                                      }}
                                      disabled={isIntervaloUnico}
                            />
                        </div>
                    </div>
                </div>
                <div className={'form-group'}>
                    <div className="row">
                    <div className="col-sm-6">
                        <label> Buscar precatórios do processo</label>
                        <InputSwitch 
                            id="findPrecatorio"
                            checked={agentState.findPrecatorios === true}  
                            onChange={(e) => {
                                setAgentState({
                                    ...agentState,
                                    findPrecatorios: e.value ? true : false
                                })
                            }}
                            disabled={isIntervaloUnico}
                        />

                    </div>
                    <div className="col-sm-6">
                            <label>Hora da execução</label>
                            <Dropdown
                                id="horaExecucao"
                                style={{ height: '37px' }}
                                placeholder="00:00"
                                options={Array.from({ length: 24 }, (_, hour) => {
                                    const formattedHour = hour.toString().padStart(2, '0') + ':00';
                                    return { label: formattedHour, value: formattedHour };
                                })}
                                className="w-100 p-inputtext-sm p-d-block p-mb-1"
                                value={agentState.horaExecucao}
                                onChange={(e) => setAgentState({
                                    ...agentState,
                                    horaExecucao: e.value
                                })}
                                disabled={isIntervaloUnico}
                            />
                    </div>
                    </div>
                </div>
            </div>
            {novoAssunto &&
                <DialogAssuntoAgente open={novoAssunto}
                                     close={() => setNovoAssunto(false)}
                                     toast={toast} refreshList={consultarAssuntos}/>
                        }
        </Dialog>
        
    );
};


