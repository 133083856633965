import React, {useState, useEffect, useRef} from 'react';
import {Toast} from 'primereact/toast';
import ResultProcessosBuscadosService from '../../app/service/ResultProcessosBuscados';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {ButtonEtrium} from '../ButtonEtrium';
import {format} from 'date-fns';
import ProcessoAutomaticoService from '../../app/service/ProcessoAutomaticoService';

const TabelaProcessos = ({user}) => {
    const [processos, setProcessos] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
    const [importingRows, setImportingRows] = useState([]); 
    const service = new ResultProcessosBuscadosService();
    const serviceProcessoAuto = new ProcessoAutomaticoService();
    const toastRef = useRef(null)
    // const [showAlert, setShowAlert] = useState(false);
    const intervalRef = useRef(null);
    const userId = user?.codigo;
    const empresaId = user?.empresa?.codigo;
    const fetchProcessos = async () => {
        try {
            const response = await service.getProcessoEmpresa(empresaId);
            setProcessos(response.data); 
        } catch (error) {
            console.error('Erro ao buscar processos:', error);
        }
    };
    useEffect(() => {

        fetchProcessos();

    }, [userId]);

    /*
     // Este useEffect é responsável por iniciar e parar o intervalo de busca automática dos processos.
    // O intervalo é iniciado quando há pelo menos um processo com status diferente de "Finalizado".
    // Ele é parado quando todos os processos estão finalizados ou quando o componente é desmontado.
    */
    useEffect(() => {

        /**
         * Start an interval to fetch processos every 10 seconds.
         * If an interval is already running, do nothing.
         */
        const startInterval = () => {
            if (intervalRef.current === null) {
                intervalRef.current = setInterval(fetchProcessos, 10000);
            }
        };

        /**
         * Stops the interval that fetches processos every 10 seconds.
         * If the interval is not running, do nothing.
         */
        const stopInterval = () => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };

        const hasActiveProcesses = processos.some(item => item.status?.toLowerCase() !== "finalizado".toLowerCase());

        if (hasActiveProcesses) {
            startInterval();
        } else {
            stopInterval();
        }

        return () => {
            stopInterval(); // Cleanup on unmount
        };
    }, [processos]);


    const formatDate = (dateString) => {
        try {
            return format(new Date(dateString), 'dd/MM/yyyy');
        } catch (error) {
            return 'Data inválida';
        }
    };

    const formatDateTime = (dateString) => {
        try {
            return format(new Date(dateString), 'dd/MM/yyyy HH:mm:ss');
        } catch (error) {
            return 'Data inválida';
        }
    };

    const rowExpansionTemplate = (data) => {
        return (
            <>
                <div
                    className="row"
                    style={{
                        display: 'flex',
                        color: '#000',
                        borderRadius: '8px',
                    }}
                >
                    <div
                        style={{
                            flex: 1,
                            padding: '0 10px',
                            margin: '8px 0',
                            borderRight: '1px solid #dcdcdc',
                        }}
                    >
                        <p style={{color: '#000', margin: '4px 0', fontSize: '15px'}}>
                            <strong>Polo Ativo:</strong> {data.poloAtivo}
                        </p>
                        <p style={{color: '#000', margin: '4px 0', fontSize: '15px'}}>
                            <strong>Polo Passivo:</strong> {data.poloPassivo}
                        </p>
                        <p style={{color: '#000', margin: '4px 0', fontSize: '15px'}}>
                            <strong>Outras Partes:</strong> {data.otherParts}
                        </p>
                    </div>
                    <div
                        style={{
                            flex: 1,
                            padding: '0 10px',
                            margin: '8px 0',
                        }}
                    >
                        <p style={{color: '#000', margin: '4px 0', fontSize: '15px'}}>
                            <strong>Data de Distribuição:</strong> {formatDate(data.distributionDate)}
                        </p>
                        <p style={{color: '#000', margin: '4px 0', fontSize: '15px'}}>
                            <strong>Eventos:</strong> {data.events ? `${formatDate(data.events.split(' - ')[0])} - ${data.events.split(' - ')[1]}` : 'Sem eventos'}
                        </p>
                        {data?.precatorios &&
                            <p style={{color: '#000', margin: '4px 0', fontSize: '15px'}}>
                            <strong>Precatórios: </strong>
                                <br/>
                                {data.precatorios ? data.precatorios.split("\n").map(
                                    (item, index) => (
                                        <span key={index}>
                                            {item}
                                            <br/>
                                        </span>
                                )): 'Sem eventos'}
                        </p>}
                    </div>
                </div>
            </>
        );
    };


    const renderImportButton = (rowData) => {
        const finalizado = rowData.status?.toLowerCase() === 'finalizado';
        const importando = importingRows.includes(rowData.cnj);
        if (!finalizado) return null;
        return (
            <ButtonEtrium
                buttonType="primary"
                disabled={importando}
                children={importando ? "Importando..." : "Importar"}
                onClick={() => handleImport(rowData)}
            />
        );
    };


    const handleImport = async (rowData) => {
        setImportingRows(prev => [...prev, rowData.cnj]);
        try {
            await serviceProcessoAuto.importar(userId, rowData.cnj, rowData.instance);
            toastRef.current.show({
                severity: 'success',
                summary: 'Sucesso',
                detail: `Processo ${rowData.cnj} da instância ${rowData.instance || 'N/A'} importado com sucesso!`,
                life: 4000
            });
            fetchProcessos();
        } catch (error) {
            toastRef.current.show({
                severity: 'error',
                summary: 'Erro',
                detail: `Falha ao importar o processo ${rowData.cnj} da instância ${rowData.instance || 'N/A'}. Erro: ${error.message}`,
                life: 4000
            });
        } finally {
            setImportingRows(prev => prev.filter(cnj => cnj !== rowData.cnj));
        }
    };
    const renderSistema = (rowData) => {
        return `${rowData.system} ${rowData.instance}`;
    };

    const renderUltimaExecucao = (rowData) => {
        return formatDateTime(rowData.updateDate);
    };

    return (
        <div className="tabela-processos">
            <Toast ref={toastRef}/>
            <DataTable
                value={processos}
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                rowExpansionTemplate={rowExpansionTemplate}
                dataKey="cnj"
                paginator
                rows={10}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                emptyMessage="Nenhum processo encontrado"
            >
                <Column field="cnj" header="CNJ"/>
                <Column field="status" header="Status"/>
                <Column header="Sistema" body={renderSistema} />
                <Column header="Última Execução" body={renderUltimaExecucao}/>
                <Column field="agente.nome" header="Agente"/>
                <Column
                    body={renderImportButton}
                    style={{width: '8em', textAlign: 'center'}}
                    header="Ações"
                />
                <Column expander style={{width: '3em'}}/>
            </DataTable>
        </div>
    );
};

export default TabelaProcessos;
