import React from 'react'

import {withRouter} from 'react-router-dom'
import {Toast} from 'primereact/toast';
import {addLocale, locale} from 'primereact/api'

import '../../node_modules/admin-lte/dist/css/adminlte.css'
import '../pages/css/plugins/fontawesome-free/css/all.min.css'
import '../../node_modules/admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css'
import '../../node_modules/admin-lte/plugins/jqvmap/jqvmap.min.css'
import '../../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css'
import '../../node_modules/admin-lte/plugins/fontawesome-free/css/all.min.css'

import '../../node_modules/admin-lte/plugins/jquery/jquery.min.js'
import '../../node_modules/admin-lte/plugins/jquery-ui/jquery-ui.min.js'
import '../../node_modules/admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js'
import '../../node_modules/admin-lte/plugins/chart.js/Chart.min.js'
import '../../node_modules/admin-lte/plugins/sparklines/sparkline.js'
import '../../node_modules/admin-lte/plugins/jquery-knob/jquery.knob.min.js'
import '../../node_modules/admin-lte/dist/js/adminlte.js'

import '../../node_modules/admin-lte/plugins/datatables/jquery.dataTables.min.js'
import '../../node_modules/admin-lte/plugins/datatables-bs4/js/dataTables.bootstrap4.min.js'
import '../../node_modules/admin-lte/plugins/datatables-responsive/js/dataTables.responsive.min.js'
import '../../node_modules/admin-lte/plugins/datatables-responsive/js/responsive.bootstrap4.min.js'
import '../../node_modules/admin-lte/plugins/datatables-buttons/js/dataTables.buttons.min.js'
import '../../node_modules/admin-lte/plugins/datatables-buttons/js/buttons.bootstrap4.min.js'

import '../../node_modules/primereact/resources/primereact.min.css'
import '../../node_modules/primereact/resources/themes/lara-light-blue/theme.css'
import '../../node_modules/primeicons/primeicons.css'

import '../pages/css/custom.css'
import './styles/navbar.css'

import LocalStorageService from '../app/service/localStorageService'
import CentralService from '../app/service/CentralService'
import UsuarioService from '../app/service/UsuarioService'
import ProcessoService from '../app/service/ProcessoService'
import PushService from '../app/service/PushService'
import {AuthContext} from '../main/ProvedorAutenticacao'
import {DateFormat} from "../utils/DateFormat";
import {Loading} from "./Loading";
import {CadastrarAtividadeDialog} from "./Atividade/CadastrarAtividadeDialog";
import {IsNullOrEmpty} from "../utils/IsNullOrEmpy";
import {PERFIL_ADM, PERFIL_PARECER, PERFIL_PARECER_MASTER} from "../utils/ConstsParecer";
import {EmpresaConsts} from "../Consts/EmpresaConsts";
import TourComponent from './TourComponent/TourComponent.jsx';
import {
    ADMIN,
    perfisAdmin,
    perfisAtividade,
    perfisFinanceiros,
    perfisParecer,
    perfisPessoas,
    perfisProcessos
} from "../Consts/PerfilConsts";
import {MenuItemWithPerfil} from "./MenuItemWithPerfil";

class Navbar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            displayAtividade: false,
            telaAntiga: window.innerWidth,
            //processo
            detail: '',
            menuShow: true,
            consultaProcessoTopo: '',
            //Central
            quantidadeNotificacoes: '',
            quantidadePush: '',
            //Alteração de Senha
            senhaUsuario: '',
            novaSenha: '',
            confirmacaoSenha: '',
            modalAlterarSenha: false,
            openSpinner: false,
            //modal cadastrar grupo
            open: false,
            usuarioLogado: null,
            tourAtivo: false
        };

        // this.tiposAtividade = [
        //     { tipo: 'Tarefa', codigo: 'T' },
        //     { tipo: 'Compromisso', codigo: 'A' },
        // ];

        this.usuarioService = new UsuarioService();
        this.processoService = new ProcessoService();
        this.centralService = new CentralService();
        this.pushService = new PushService();
        this.toggleTour = this.toggleTour.bind(this);
    }

    createEvent() {
        window.addEventListener("resize", this.onResizeEvent, true);
    }

    onResizeEvent = () => {
        let bodyElement = window.document.getElementsByTagName("BODY")[0];
        if (bodyElement.offsetWidth < 768) {
            this.setState({menuShow: false, telaAntiga: 768})
        } else if (bodyElement.offsetWidth > 992 && this.state.telaAntiga < 992 && !this.state.menuShow) {
            this.setState({menuShow: true, telaAntiga: 992})
        } else if (bodyElement.offsetWidth > 992 && this.state.telaAntiga < 992 && this.state.menuShow) {
            this.setState({menuShow: true, telaAntiga: 992})
        } else if (bodyElement.offsetWidth > 992 && this.state.menuShow && this.state.telaAntiga >= 992) {
            this.setState({menuShow: true, telaAntiga: 992})
        } else if (bodyElement.offsetWidth > 992 && !this.state.menuShow && this.state.telaAntiga >= 992) {
            this.setState({menuShow: false, telaAntiga: 992})
        }
    }

    sairSistema = () => {
        const usuarioLogado = this.context.usuarioAutenticado;
        this.usuarioService.logout({
            usuario: usuarioLogado,
            tipoLog: 'S'
        }).then(() => {
            this.context.encerrarSessao();
        }).catch(() => {
            console.log('Erro ao sair do sistema');
        });
    }

    toggleTour = () => {
        this.setState(prevState => ({
            tourAtivo: !prevState.tourAtivo
        }));
    }

    limparConsultaProcesso = () => {
        LocalStorageService.limparConsultaProcesso();
    }

    limparConsultarPessoas = () => {
        LocalStorageService.limparConsultaPessoas();
    }

    consultarProcessoTopo = () => {
        const usuarioLogado = this.context.usuarioAutenticado

        const {consultaProcessoTopo} = this.state
        if (!IsNullOrEmpty(consultaProcessoTopo) && consultaProcessoTopo.length > 2) {
            const indice = {
                indice: consultaProcessoTopo,
                empresa: usuarioLogado.empresa.codigo,
                usuario: usuarioLogado.codigo
            }


            this.limparConsultaProcesso();
            LocalStorageService.adicionarConsulta('_consulta_processo_indice', indice);
            this.props.history.push('/processos');
            this.processoService.consultarProcessoQueryLike(indice)
                .then(response => {
                }).catch(error => {
                this.setState(error.response.data);
                this.toast.show({severity: 'error', summary: 'Processos', detail: `${this.state.detail}`, life: 4000});
            });

        } else {
            // this.toast.show({ severity: 'error', summary: 'Processos', detail: `Digite pelo menos 3`, life: 4000 });
        }
    }


    refreshPage = () => {
        window.location.reload();
    }

    notificacoesQuantidade = async () => {
        const usuarioLogado = this.context.usuarioAutenticado;
        await this.setState({usuarioLogado: this.context.usuarioAutenticado})
        this.centralService.notificacoesQuantidade(usuarioLogado.codigo)
            .then(response => {
                this.setState({quantidadeNotificacoes: response.data});
            }).catch(error => {
            this.setState(error.response.data);
            this.toast.show({
                severity: 'error',
                summary: 'Central de notificacoes',
                detail: `${this.state.detail}`,
                life: 4000
            });
        })
    }
    // pad2 = (n) => {
    //     return (n < 10 ? '0' : '') + n;
    // }

    changeMenu = () => {
        if (this.state.menuShow) {
            this.setState({menuShow: false})
        } else {
            this.setState({menuShow: true})
        }
    }

    quantidadePush = () => {
        const usuarioLogado = this.context.usuarioAutenticado;

        const dataAtual = DateFormat(new Date())

        this.pushService.quantidadePush(usuarioLogado.empresa.codigo, dataAtual)
            .then(response => {
                this.setState({quantidadePush: response.data});
            }).catch(() => {
            // this.setState(error.response.data);
            // this.toast.show({ severity: 'error', summary: 'Push do dia', detail: `${this.state.detail}`, life: 4000 });
        })
    }


    componentDidMount() {
        this.notificacoesQuantidade();
        this.quantidadePush();
        this.createEvent()
    }


    render() {
        const usuarioLogado = this.context.usuarioAutenticado;
        const empresaPlano = usuarioLogado.empresa.plano || null
        const empresa = usuarioLogado.empresa.cpfCnpj || null
        const usuarioNome = usuarioLogado.nome.split(" ")[0]
        const usuarioPerfil = usuarioLogado.perfil.codigo;
        var gerencia = 'none';

        if (usuarioPerfil === 1) {
            gerencia = 'hide';
        }

        const notif = this.state.quantidadeNotificacoes === 0 ? '' : this.state.quantidadeNotificacoes
        const push = this.state.quantidadePush === 0 ? '' : this.state.quantidadePush
        const totalCountNotify = notif + push

        addLocale('es', {
            firstDayOfWeek: 1,
            dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
            dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
            dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
            monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
            today: 'Hoy',
            clear: 'Claro'
        });

        locale('es');

        return (
            <>
            <TourComponent showTourProp={this.state.tourAtivo} toggleTour={this.toggleTour} user={usuarioLogado}/>
            <div
                className="hold-transition sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed">
                <Toast ref={(el) => this.toast = el}/>
                <nav className="main-header navbar navbar-expand navbar-white navbar-light border-blue">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            {/*// eslint-disable-next-line*/}
                            {!this.state.menuShow &&
                                <a href={''} className="nav-link" data-auto-collapse-size="20"
                                   data-widget="pushmenu" onClick={this.changeMenu}
                                   role="button"><i
                                    className="fas fa-bars"></i></a>}
                        </li>
                    </ul>

                    <ul className="navbar-nav ml-auto bodyBar2">
                        <li className="nav-item">
                            <div style={{paddingRight: '8px'}}>
                            <form onSubmit={this.consultarProcessoTopo} className="form-inline">
                                <div className="input-group input-group-sm">
                                    <input className="form-control form-control-navbar width-control"
                                           type="input"
                                           placeholder="Digite aqui para pesquisar processos"
                                           aria-label="Search"
                                           value={this.state.consultaProcessoTopo}
                                           onChange={e => this.setState({consultaProcessoTopo: e.target.value})}/>
                                    <div className="input-group-append">
                                        <button className="btn btn-navbar" type="submit">
                                            <i className="fas fa-search"></i>
                                        </button>
                                        {/* <button className="btn btn-navbar" type="button"
                                            data-widget="navbar-search">
                                            <i className="fas fa-times"></i>
                                        </button> */}
                                    </div>
                                </div>
                            </form>
                            </div>
                        </li>
                        {empresaPlano && empresaPlano.tipoPlano.codigo === EmpresaConsts.TRY &&
                            <li className={"nav-item"}>
                            <a href="#/contratarPlano" className="nav-link cursor" title="Contratar um plano">
                                <i id='planos' className="nav-icon fas fa-cart-plus"></i>
                            </a>
                        </li>}
                        <li className="nav-item">
                            <a href="#/cadastrarProcesso" className="nav-link cursor" title="Cadastrar processo">
                                <i id='CadProcessoNav' className="nav-icon fas fa-balance-scale"></i>
                            </a>
                        </li>

                        <li className="nav-item">
                            <span className="nav-link cursor"
                                  onClick={() => this.setState({displayAtividade: true})}
                                  title="Cadastrar atividade">
                                <i id='CadAtvdNav' className="nav-icon fa fa-solid fa-network-wired"></i>
                            </span>
                        </li>

                        {/*Cadastrar Atividade*/}
                        {this.state.displayAtividade && <CadastrarAtividadeDialog
                            isOpen={this.state.displayAtividade}
                            onClose={() => this.setState({displayAtividade: false})}
                            toast={this.toast}
                            refresh={this.refreshPage}

                        />}

                        <Loading open={this.state.openSpinner}/>


                        <li className="nav-item dropdown bodyBar2">
                            <span className="nav-link cursor" data-toggle="dropdown">
                                <i id='CadNotiNav' className="far fa-bell"></i>
                                {totalCountNotify && <span
                                    className="notify-count">{totalCountNotify}</span>}
                            </span>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                <span className="dropdown-item dropdown-header">Notificações</span>
                                <div className="dropdown-divider"></div>
                                <a href={"#/centralAtividades"} className="dropdown-item">
                                    <i className="fas fa-envelope mr-2"></i> {notif} Central
                                    {/*<span className="float-right text-muted text-sm">3 mins</span>*/}
                                </a>
                                <div className="dropdown-divider"></div>
                                <a href={"#/push"} className="dropdown-item">
                                    <i className="fas fa-level-down-alt"></i> {push} Push
                                    {/*<span className="float-right text-muted text-sm">12 hours</span>*/}
                                </a>
                                <div className="dropdown-divider"></div>
                                <div className="dropdown-divider"></div>
                            </div>
                        </li>
                        <li className="nav-item dropdown user-size">
                            <span className="nav-link" data-toggle="dropdown">
                                    {/*// eslint-disable-next-line to the*/}
                                <button id='NameUserNav' className="btn-link" style={{paddingTop: 0, paddingBottom: 0}}>
                                        <span className="text-dark font-bold">Olá, </span>
                                        <span className="text-dark font-bold">{usuarioNome}</span>
                                    </button>

                            </span>

                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                <span className="dropdown-item dropdown-header">{usuarioLogado.perfil.nome}</span>
                                {/*// eslint-disable-next-line to the*/}
                                {/*<a className="dropdown-item cursor">*/}
                                {/*    <i className="fas fa-users-cog mr-2"></i>Configurações*/}
                                {/*</a>*/}
                                <div className="dropdown-divider"></div>
                                {usuarioPerfil === 1 ?<a id='DadosEmpresa' href={"#/editarEmpresa"} className="dropdown-item"
                                    style={{display: `${usuarioNome === '1' ? 'none' : 'block'}`}}>
                                    <i className="pi pi-building mr-2"></i> Alterar dados da empresa
                                </a>: <></>}
                                <a href={"#/preferencias"} className="dropdown-item">
                                    <i className="pi pi-cog mr-2"></i> Preferências
                                </a>
                                <a id='start-tour' className="dropdown-item" onClick={this.toggleTour} style={{cursor: 'pointer'}}>
                                    <i className="pi pi-map mr-2"></i> Iniciar Tour
                                </a>
                                <a href={"#/login"} onClick={this.sairSistema} className="dropdown-item">
                                    <i className="fas fa-sign-out-alt mr-2"></i> Sair
                                </a>
                            </div>
                        </li>
                    </ul>
                </nav>

                <aside className="main-sidebar elevation-4 ">
                    {this.state.menuShow &&
                        <a href={''} className="nav-link secondary-button" data-widget="pushmenu"
                           onClick={this.changeMenu}
                           role="button"><i
                            className="fas fa-bars"></i></a>
                    }
                    <div className="d-flex justify-content-center align-items-center flex-1">

                    <a href={"/#/home"} className="d-flex brand-link no-effect justify-content-center align-items-center" style={{paddingBottom: "10px"}}>
                        <img src={this.context.logoEmpresa} alt="Logo da empresa" className="img-logo-home"/>
                        {/*<span className="brand-text font-weight-light img-logo-home text">Etrium</span>*/}
                    </a>
                    </div>

                    <div className="sidebar bodyBar">
                        <nav>
                            <br/>
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                data-accordion="false">
                                <MenuItemWithPerfil perfilList={perfisProcessos} ususarioPerfil={usuarioPerfil}
                                                    element={
                                                        <li className="nav-item">
                                                        <a href="#/agentes" className="nav-link">
                                                            <i className="fas fa-robot nav-icon"></i>
                                                            <p>Agente AI</p>
                                                        </a>
                                                    </li>}/>
                                <MenuItemWithPerfil perfilList={perfisAtividade} ususarioPerfil={usuarioPerfil}
                                                    element={<li className="nav-item">
                                                                <span className="nav-link cursor">
                                                                    <i className="nav-icon fa fa-solid fa-network-wired"></i>
                                                                    <p>
                                                                        Atividades
                                                                        <i className="fas fa-angle-left right"></i>
                                                                    </p>
                                                                </span>
                                                        <ul className="nav nav-treeview">
                                                            <li className="nav-item padding-sub-item">
                                                                <a href={"#/home"} className="nav-link">
                                                                    <i className="fas fa-chalkboard-teacher nav-icon"></i>
                                                                    <p>Meu quadro</p>
                                                                </a>
                                                            </li>
                                                            <li className="nav-item padding-sub-item">
                                                                <a href={"#/agenda"} className="nav-link">
                                                                    <i className="fas fa-calendar-alt nav-icon"></i>
                                                                    <p>Minha agenda</p>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>}/>
                                <MenuItemWithPerfil perfilList={perfisProcessos} ususarioPerfil={usuarioPerfil}
                                                    element={<li className="nav-item">
                                    <span className="nav-link cursor">
                                        <i className="nav-icon fas fa-balance-scale"></i>
                                        <p>
                                            Processos
                                            <i className="fas fa-angle-left right"></i>
                                        </p>
                                    </span>
                                                        <ul className="nav nav-treeview">
                                                            <li className="nav-item padding-sub-item">
                                                                <a href={"#/cadastrarProcesso"} className="nav-link">
                                                                    <i className="fa fa-solid fa-robot nav-icon"></i>
                                                                    <p>Cadastro automático</p>
                                                                </a>
                                                            </li>
                                                            <li className="nav-item padding-sub-item">
                                                                <a href={"#/cadastrarProcessoManual"}
                                                                   className="nav-link">
                                                                    <i className="fas fa-plus-circle nav-icon"></i>
                                                                    <p>Cadastro manual</p>
                                                                </a>
                                                            </li>
                                                            <li className="nav-item padding-sub-item">
                                                                <a href={"#/processos"}
                                                                   onClick={this.limparConsultaProcesso}
                                                                   className="nav-link">
                                                                    <i className="fas fa-search-plus nav-icon"></i>
                                                                    <p>Pesquisar</p>
                                                                </a>
                                                            </li>
                                                            {usuarioPerfil === ADMIN ?
                                                                <li className="nav-item padding-sub-item">
                                                                    <a href={"/#processosExcluidos"}
                                                                       className="nav-link">
                                                                        <i className="pi pi-times-circle nav-icon"></i>
                                                                        <p>Processos excluídos</p>
                                                                    </a>
                                                                </li> : <></>}
                                                        </ul>
                                                    </li>}/>
                                <MenuItemWithPerfil perfilList={perfisPessoas} ususarioPerfil={usuarioPerfil}
                                                    element={
                                                        <li className="nav-item">
                                    <span className="nav-link cursor">
                                        <i className="nav-icon fas fa-user-tie"></i>
                                        <p id='nav-pessoa'>
                                            Pessoas
                                            <i className="fas fa-angle-left right"></i>
                                        </p>
                                    </span>
                                                            <ul className="nav nav-treeview">
                                                                <li className="nav-item padding-sub-item">
                                                                    <a href={"#/cadastrarPessoa"}
                                                                       className="nav-link">
                                                                        <i className="fas fa-plus-circle nav-icon"></i>
                                                                        <p id='nav-cd-pessoa'>Cadastrar</p>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item padding-sub-item">
                                                                    <a href={"#/pessoas"}
                                                                       onClick={this.limparConsultarPessoas}
                                                                       className="nav-link">
                                                                        <i className="fas fa-search-plus nav-icon"></i>
                                                                        <p>Pesquisar</p>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </li>}/>
                                <MenuItemWithPerfil perfilList={perfisFinanceiros} ususarioPerfil={usuarioPerfil}
                                                    element={
                                                        <li className="nav-item">
                                                            <a href={"#/painelFinanceiro"} className="nav-link"
                                                            >
                                                                <i className="fas fa-hand-holding-usd nav-icon"></i>
                                                                Financeiro
                                                            </a>
                                                        </li>}/>
                                {/*{perfisParecer.includes(usuarioPerfil) ?*/}
                                {/*    <li className="nav-item">*/}
                                {/*        <a href={"#/parecer"} className="nav-link"*/}
                                {/*           style={{display: `${usuarioNome === '1' ? 'none' : 'block'}`}}>*/}
                                {/*            <i className="fas fa-regular fa-file nav-icon"></i>*/}
                                {/*            /!*<a href={"#/painelFinanceiro"} className="nav-link">*!/*/}
                                {/*            Parecer*/}
                                {/*        </a>*/}
                                {/*    </li>: <></>}*/}
                                <MenuItemWithPerfil perfilList={perfisParecer} ususarioPerfil={usuarioPerfil}
                                                    element={
                                                        <li className="nav-item">
                                                            <a href={"#/parecer"} className="nav-link"
                                                               id={"nav-parecer"}
                                                               style={{display: `${usuarioNome === '1' ? 'none' : 'block'}`}}>
                                                                <i className="fas fa-regular fa-file nav-icon"></i>
                                                                Parecer
                                                            </a>
                                                        </li>
                                                    }/>
                                {/*central de documentos temporarioamente removida*/}
                                {/*<li className="nav-item">*/}
                                {/*    <a  className="nav-link">*/}
                                {/*        <i className="nav-icon fas fa-archway"></i>*/}
                                {/*        <p>*/}
                                {/*            Central de Documentos*/}
                                {/*        </p>*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                                {/*temporaraiamente relatorio processo*/}

                                <MenuItemWithPerfil perfilList={perfisAdmin} ususarioPerfil={usuarioPerfil}
                                                    element={
                                                        <li className="nav-item">
                                                            <a href="#/relatoriosFinanceiro" id={'nav-relatorio'}
                                                               className="nav-link">
                                                                <i className="nav-icon fas fa-file-export"></i>
                                                                <p>
                                                                    Relatórios
                                                                    <i className="fas fa-angle-left right"></i>
                                                                </p>
                                                            </a>
                                                            <ul className="nav nav-treeview">
                                                                <li className="nav-item padding-sub-item">
                                                                    <a href="#/relatorioProcessos"
                                                                       id={"nav-relatorio-processos"}
                                                                       className="nav-link">
                                                                        <i className="fas fa-file-contract nav-icon"></i>
                                                                        <p>Processos</p>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item padding-sub-item">
                                                                    <a href="#/relatoriosFinanceiro"
                                                                       id={"nav-relatorio-financeiro"}
                                                                       className="nav-link">
                                                                        <i className="fas fa-file-contract nav-icon"></i>
                                                                        <p>Financeiro</p>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </li>}/>

                                <MenuItemWithPerfil perfilList={perfisAdmin} ususarioPerfil={usuarioPerfil}
                                                    element={<>
                                                        <li className="nav-item" style={{display: 'hide'}}>
                                                                <span className="nav-link cursor"
                                                                      style={{display: `${gerencia}`}}>
                                                                    <i className="nav-icon fas fa-tachometer-alt"></i>
                                                                    <p>
                                                                        Painel
                                                                        <i className="right fas fa-angle-left"></i>
                                                                    </p>
                                                                </span>
                                                            <ul className="nav nav-treeview">
                                                                <li className="nav-item padding-sub-item">
                                                                    <a href={"#/graficosAtividades"}
                                                                       className="nav-link">
                                                                        <i className="nav-icon fa fa-solid fa-network-wired"></i>
                                                                        <p>Atividades</p>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item padding-sub-item">
                                                                    <a href={"#/painelProcesso"} className="nav-link">
                                                                        <i className="nav-icon fas fa-balance-scale"></i>
                                                                        <p>Processos</p>
                                                                    </a>
                                                                </li>
                                                                {usuarioPerfil === PERFIL_ADM |
                                                                    usuarioPerfil === PERFIL_PARECER |
                                                                    usuarioPerfil === PERFIL_PARECER_MASTER &&
                                                                    <li className="nav-item padding-sub-item">
                                                                        <a href={"#/painelParecer"} className="nav-link"
                                                                           style={{display: `${usuarioNome === '1' ? 'none' : 'block'}`}}>
                                                                            <i className="fas fa-regular fa-file nav-icon"></i>
                                                                            {/*<a href={"#/painelFinanceiro"} className="nav-link">*/}
                                                                            Parecer
                                                                        </a>
                                                                    </li>}

                                                                {empresa === EmpresaConsts.ETRIUM &&
                                                                    <li className="nav-item padding-sub-item">
                                                                        <a href={"#/empresa/painel"}
                                                                           className="nav-link">
                                                                            <i className="pi pi-building nav-icon"></i>
                                                                            <p> Empresas</p>
                                                                        </a>
                                                                    </li>}
                                                                {empresa === EmpresaConsts.ETRIUM &&
                                                                    <li className="nav-item padding-sub-item">
                                                                        <a href={"#/empresa/agentes/painel"}
                                                                           className="nav-link">
                                                                            <i className="fas fa-robot nav-icon"></i>
                                                                            <p> Agentes</p>
                                                                        </a>
                                                                    </li>}
                                                            </ul>
                                                        </li>

                                                        <li className="nav-item">
                                        <span id='LinkAdm' className="nav-link cursor d-block"
                                              style={{display: `${usuarioNome === '1' ? 'none' : 'block'}`}}>
                                            <i className="nav-icon fas fa-cogs"></i>
                                            <p>
                                                Administração
                                                <i className="fas fa-angle-left right"></i>
                                            </p>
                                        </span>
                                                            <ul className="nav nav-treeview">
                                                                <li className="nav-item padding-sub-item">
                                                                    <a href="#/usuarios" className="nav-link">
                                                                        <i className="fas fa-users nav-icon"></i>
                                                                        <p>Usuários</p>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item padding-sub-item">
                                                                    <a href="#/grupoTrabalho" className="nav-link">
                                                                        <i className="fas fa-boxes nav-icon"></i>
                                                                        <p>Grupos de trabalho</p>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item padding-sub-item">
                                                                    <a href="#/gruposPessoas" className="nav-link">
                                                                        <i className="fas fa-people-carry nav-icon"></i>
                                                                        <p>Grupos de pessoas</p>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item padding-sub-item">
                                                                    <a href="#/tiposDespesasReceitas"
                                                                       className="nav-link">
                                                                        <i className="fa fas fa-shapes nav-icon"></i>
                                                                        <p>Despesas e receitas</p>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item padding-sub-item">
                                                                    <a href="#/objetosAcao" className="nav-link">
                                                                        <i className="far fa-object-ungroup nav-icon"></i>
                                                                        <p>Objetos de ação</p>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item padding-sub-item">
                                                                    <a href="#/statusProcessual" className="nav-link">
                                                                        <i className="fas fa-stream nav-icon"></i>
                                                                        <p>Status processual</p>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item padding-sub-item">
                                                                    <a href="#/tiposGarantias" className="nav-link">
                                                                        <i className="fas fa-university nav-icon"></i>
                                                                        <p>Garantias de processo</p>
                                                                    </a>
                                                                </li>

                                                                <li className="nav-item padding-sub-item">
                                                                    <a href="#/tiposPagamentos" className="nav-link">
                                                                        <i className="fas fa-cash-register nav-icon"></i>
                                                                        <p>Tipos de pagamentos</p>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item padding-sub-item">
                                                                    <a href="#/tiposParecer" className="nav-link">
                                                                        <i className="fas fa-file nav-icon"></i>
                                                                        <p>Tipos parecer</p>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item padding-sub-item">
                                                                    <a href="#/tiposCustas" className="nav-link">
                                                                        <i className="fas fa-dollar-sign nav-icon"></i>
                                                                        <p>Tipos de custas</p>
                                                                    </a>
                                                                </li>


                                                                <li className="nav-item padding-sub-item">
                                                                    <a href="#/tiposDecisao" className="nav-link">
                                                                        <i className="fab fa-codepen nav-icon"></i>
                                                                        <p>Tipos de decisão</p>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item padding-sub-item">
                                                                    <a href={"#/acompanhamento"} className="nav-link"
                                                                    >
                                                                        <i className="fa fa-history nav-icon"></i>
                                                                        Acompanhamento
                                                                    </a>
                                                                </li>

                                                            </ul>
                                                        </li>
                                                    </>
                                                    }/>

                                <li className="nav-item">
                                    <a href={"#/suporteUsuario"} className="nav-link d-block"
                                    >
                                        <i className="fas fa-question-circle nav-icon"></i>
                                        <p>
                                            Suporte
                                            {/*<i className="fas fa-angle-left right"></i>*/}
                                        </p>
                                    </a>
                                </li>
                                {/*<li className="nav-item">*/}
                                {/*    <a href={"#/atendimentos"} className="nav-link">*/}
                                {/*        <i className="nav-icon pi pi-comments"></i>*/}
                                {/*        <p>*/}
                                {/*            Atendimento*/}
                                {/*        </p>*/}
                                {/*    </a>*/}
                                {/*</li>*/}


                            </ul>

                        </nav>
                        {this.state.usuarioLogado && (
                            <div className="empresa-style">
                                <i className="pi pi-building"></i>
                                <span>
                                {/*{this.state.usuarioLogado.empresa.nome} -*/}
                                    {this.state.usuarioLogado.empresa.cpfCnpj}
                                </span>
                            </div>)}
                    </div>
                </aside>
            </div>
            </>

        )
    }
}

Navbar.contextType = AuthContext;

export default withRouter(Navbar)
