import { nomesMeses } from "./nomes-meses";
import { IsNullOrEmpty } from "./IsNullOrEmpy";

export const DateFormat = (date) => {
    return ((date.getFullYear() + "-" + pad2(date.getMonth() + 1) + "-" + pad2(date.getDate())))
}

export const DateFormatHour = (date) => {
    return ((date.getFullYear() + "-" + pad2(date.getMonth() + 1) + "-" + pad2(date.getDate()) + " " + pad2(date.getHours()) + ":" + pad2(date.getMinutes())));
}
//colocando 0 antes do dia e ano caso eles sejam apenas de um digito 0-9
const pad2 = (n) => {
    return (n < 10 ? '0' : '') + n;
}

export function FormatDataString(dataString) {
    if (dataString.toString().length === 0)
        return ""

    // dividir a string em dia, mês e ano
    const [dia, mes, ano] = dataString.split('/');

    // criar uma nova string de data no formato yyyy-mm-dd
    return `${ano}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`;

}
export function formatDateHoraTela(dataString) {
    if (IsNullOrEmpty(dataString))
        return ""

    // Criando um objeto Date a partir da string original
    let dataHora = new Date(dataString);

    // Obtendo os componentes da data e hora
    let dia = dataHora.getDate();
    let mes = dataHora.getMonth() + 1; // Os meses começam do zero, então é necessário adicionar 1
    let ano = dataHora.getFullYear();
    let hora = dataHora.getHours();
    let minutos = dataHora.getMinutes();

    // Formatando a data e hora no formato desejado
    if (hora === 0 && minutos === 0) {
        return pad2(dia) + "/" + pad2(mes) + "/" + ano
    }
    return pad2(dia) + "/" + pad2(mes) + "/" + ano + " " + pad2(hora) + ":" + pad2(minutos)

}
export function formatDateHoraImpressao() {
    const dayName = ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'];

    // Criando um objeto Date a partir da string original
    let data = new Date();

    // Obtendo os componentes da data e hora
    let dia = data.getDate();
    let mes = data.getMonth(); // Os meses começam do zero, então é necessário adicionar 1
    let ano = data.getFullYear();
    let hora = data.getHours();
    let minutos = data.getMinutes();
    // let segundos = data.getSeconds()

    return (<div>
        <span>Em: {dayName[data.getDay()]}, {pad2(dia)} de {nomesMeses[mes]} de {ano} </span>
        <span>às {pad2(hora)}:{pad2(minutos)}</span>
    </div>
    )

}
export const formatDateToStringTela = (dataString) => {
    if (IsNullOrEmpty(dataString)) {
        return dataString
    }
    let data = new Date(dataString);
    // Separar a string em componentes de ano, mês e dia usando split()
    let dia = data.getDate();
    let mes = data.getMonth() + 1; // Os meses começam do zero, então é necessário adicionar 1
    let ano = data.getFullYear();

    // Montar a data formatada no formato "DD/MM/YYYY"
    return pad2(dia) + "/" + pad2(mes) + "/" + ano;
}

export function verificaHoraZerada(data) {
    return data.getHours() === 0 && data.getMinutes() === 0 && data.getSeconds() === 0 && data.getMilliseconds() === 0;
}

export function primeiroDiaDoMes() {
    const hoje = new Date();
    return new Date(hoje.getFullYear(), hoje.getMonth(), 1);
}

export function ultimoDiaDoMes() {
    const hoje = new Date();
    return new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0);
}

export function diferencaMaiorQue31Dias(data1, data2) {
    const umDiaEmMilissegundos = 24 * 60 * 60 * 1000; // Número de milissegundos em um dia
    const diferencaEmMilissegundos = Math.abs(data2 - data1);
    const diferencaEmDias = diferencaEmMilissegundos / umDiaEmMilissegundos;

    return diferencaEmDias > 31;
}

/**
 * Splits a string representation of a date into its components and returns a new Date object.
 *
 * @param {string} dataStr - The string representation of the date in the format "YYYY-MM-DD".
 * @return {Date} A new Date object representing the date.
 */
export function obterDataApartirDeDataString(dataStr) {
    let parts = dataStr.split("-");
    return new Date(parts[0], parts[1] - 1, parts[2]);
}

export function formatDateAgent(dateString) {
  if (!dateString) return "";
  const date = new Date(dateString + "T00:00:00");
  return pad2(date.getDate()) + "/" + pad2(date.getMonth() + 1) + "/" + date.getFullYear();
}


export function getCurrentHour () {
    const now = new Date();
    const hour = now.getHours().toString().padStart(2, "0");
    return `${hour}:00`;
};


export const formatarData = (dataStr) => {
    const data = new Date(dataStr);
    return data.toLocaleDateString();
};

export const formatarHoraAgente = (hourStr) => {
    if (!hourStr) return "";
    const parts = hourStr.split(":");
    if (parts.length >= 2) {
      return `${parts[0]}:${parts[1]}`;
    }
    return hourStr;
  };