import ApiService from "../apiservice";

export default  class AgenteService extends ApiService{
    constructor() {
        super("/agentes");
    }

    async getAgentesByEmpresa(empresa) {
        return await this.get(`/empresa/${empresa}`);
    }

    async getAgenteById(id) {
        return await this.get(`/${id}`);
    }

    async getAgenteDetalhadoById(id) {
        return await this.get(`/listAgenteDetalhado/${id}`);
    }

    async getAllAgentes(empresaId) {
        return await this.get(`/listAll/${empresaId}`);
    }

    async createAgente(agente) {
        return await this.post('', agente);
    }
    async updateAgente(id, agente) {
        return await this.put(`/${id}`, agente);
    }
    async deleteAgente(id) {
        return await this.delete(`/${id}`);
    }
}