import ApiService from "../apiservice";

export default  class AgenteAssuntoService extends ApiService{
    constructor() {
        super("/agentes/assuntos");
    }

    async createAssunto(assunto) {
        return await this.post('', assunto);
    }

    async getAssuntosByEmpresa(empresa) {
        return await this.get(`/empresa/${empresa}`);
    }

    async updateAssunto(id, assunto) {
        return await this.put(`/${id}`, assunto);
    }
    async deleteAgente(id) {
        return await this.delete(`/${id}`);
    }
}