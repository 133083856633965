import {PERFIL_PARECER_MASTER} from "../utils/ConstsParecer";


export const ADMIN = 1;
export const ADVOGADO_SENIOR = 2;
export const ADVOGADO = 3;
export const ESTAGIARIO = 4;
export const ADMINISTRATIVO = 5;
export const FINANCEIRO = 6;
export const CONSULTA = 7;
export const PARECER = 10;
export const PARECER_MASTER = 11;
export const PARECER_ADMIN = 14;
export const PRECATORIO = 15;

export const perfisAdmin = [ADMIN];
export const perfisFinanceiros = [ADMIN, ADMINISTRATIVO];
export const perfisParecer = [ADMIN, PARECER, PARECER_MASTER, PARECER_ADMIN];
export const perfisProcessos = [ADMIN, ADVOGADO_SENIOR, ADVOGADO, ADMINISTRATIVO, FINANCEIRO, ESTAGIARIO, PERFIL_PARECER_MASTER, PARECER_ADMIN, CONSULTA, PRECATORIO];
export const perfisAtividade = [ADMIN, ADVOGADO_SENIOR, ADVOGADO, ADMINISTRATIVO, FINANCEIRO, ESTAGIARIO, PERFIL_PARECER_MASTER, PARECER_ADMIN, PRECATORIO];
export const perfisPessoas = [ADMIN, ADVOGADO_SENIOR, ADVOGADO, ADMINISTRATIVO, FINANCEIRO, ESTAGIARIO, PERFIL_PARECER_MASTER, PARECER_ADMIN, PRECATORIO];
